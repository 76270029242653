const initState = {
    filterHide: true
}

const filterReducer = (state = initState, action) => {
    switch (action.type) {
        case 'UPDATE_FILTER_HIDE':
            return {
                ...state,
                filterHide: !state.filterHide,
            }
        default:
            return state
    }
}

export default filterReducer