import React, { Component } from 'react'
import ReactModal from 'react-modal'

import upload_modal_corner from './../../../../../img/kyc/uploadModal/upload_modal_corner.svg'

import './../styles/uploadConfirmationModal.css'

export default class UploadConfirmationModal extends Component {
    constructor() {
        super();
        this.state = {
            showModal: false,
            comment: null
        };


        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    handleOpenModal() {
        this.setState({ showModal: true });
    }

    handleCloseModal() {
        this.setState({ showModal: false });
    }

    handleChange(text) {
        this.setState({
            comment: text
        })
    }

    render() {
        console.log(this.props.modalInfo)
        return (
            <div className="modal-container upload-confiramtion-modal-container">
                <ReactModal
                    isOpen={this.props.showModal}
                    contentLabel="onRequestClose Example"
                    onRequestClose={() => this.props.handleCloseModal()}
                    shouldCloseOnOverlayClick={true}
                    className="upload-confirmation-modal"
                >
                    <img
                        src={upload_modal_corner}
                        alt="right-top"
                        className="modal-corner"
                    />
                    <div className="row header">
                        <div className="col s12 l12">
                            <div className="heading">
                                {/* Rejection Reason */}
                                <span className="sub-heading">
                                    {/* Select or type reason for rejection */}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="body">
                            <img className="upload-response" src={this.props.image} alt="response" />
                            <div className="info">
                                {this.props.type === 'rejection' ?
                                    <>
                                        <div className="data">
                                            <div className="value not-found">
                                                {this.props.modalInfo.not_found_count}
                                            </div>
                                            <div className="tag">
                                                Not Found
                                        </div>
                                        </div>
                                        <div className="data">
                                            <div className="value fail">
                                                {this.props.modalInfo.fail_count}
                                            </div>
                                            <div className="tag">
                                                Rejected
                                        </div>
                                        </div>
                                    </>
                                    : <>
                                        <div className="data">
                                            <div className="value not-found">
                                                {this.props.modalInfo.not_found_count}
                                            </div>
                                            <div className="tag">
                                                Not Found
                                            </div>
                                        </div>
                                        <div className="data">
                                            <div className="value pass">
                                                {this.props.modalInfo.pass_count}
                                            </div>
                                            <div className="tag">
                                                Activated
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="status">
                                Successfully Marked!
                            </div>
                            <button
                                className="great"
                                onClick={() => this.props.handleCloseModal()}
                            >Great !</button>
                        </div>
                    </div>
                </ReactModal>
            </div >
        )
    }
}