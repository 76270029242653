import React, { Component } from "react"
import axios from "axios"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"

import Outlet from "./components/outletCard"
import LoadingCard from "./components/loading"

import FilterElement from "./../layout/FilterElement"
import Pagination from "../layout/Pagination"

import { updateFilterElement } from "../../store/actions/filterElementAction"

import "./style.css"
import "./../layout/styles/filter.css"
import Footer from "../Footer/Footer"

class Outlets extends Component {
  constructor(props) {
    super(props)

    this.state = {
      outlets: null,
      total: null,
      limit: null,
      search_term: "",

      data_fetching: false,
    }
  }

  componentDidMount() {
    this.set_data_set()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.currentPage !== prevProps.currentPage ||
      this.props.currentLimit !== prevProps.currentLimit
    )
      this.set_data_set()
  }

  set_data_set = () => {
    this.setState({
      data_fetching: true,
    })

    axios
      .get(
        `https://${process.env.REACT_APP_API_URL}/api/outlets/v1/list?page_index=${this.props.currentPage}&page_size=${this.props.currentLimit}&search_term=${this.state.search_term}`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
          },
        }
      )
      .then((res) => {
        return this.setState({
          outlets: res.data.data,
          total: res.data.count,
          limit: res.data.limit,

          data_fetching: false,
        })
      })
      .catch(() =>
        this.setState({
          data_fetching: false,
        })
      )
  }

  create_filter_elements = (filter, type) =>
    filter.map((item, index) => (
      <FilterElement
        key={index}
        {...item}
        selectedValue={this.props.filterElement}
        type={type}
      />
    ))

  render() {
    const localUserToken = JSON.parse(localStorage.getItem("currUserToken"))
    if (!localUserToken) return <Redirect to="/signin" />
    return (
      <div className="main-container">
        <div className="outlets">
          <div className="page-header">
            <div className="page-heading-search">
              <div className="page-heading">
                Outlets
                <div className="page-sub-heading">
                  {this.state.total} Outlets in Dexter Database
                </div>
              </div>
              <input
                type="text"
                className="search_outlet"
                placeholder="Search by Wallet Number"
                onChange={(e) =>
                  this.setState(
                    {
                      search_term: e.target.value,
                    },
                    () => this.set_data_set()
                  )
                }
              />
            </div>
            <div className="page-settings"></div>
          </div>

          <Pagination
            page={"outlets"}
            currentPage={this.props.currentPage}
            total={this.state.total}
            limit={this.props.currentLimit}
          />

          {this.state.data_fetching ? (
            <LoadingCard
            // card_heading={`${item.card_heading}`}
            />
          ) : (
              this.state.outlets && (
                <div className="card-row row">
                  {this.state.outlets.map((item, index) => (
                    <div key={index} className="card-col col l4 m12 s12">
                      <Outlet outlet={item} index={index} />
                    </div>
                  ))}
                </div>
              )
            )}

          <Pagination
            page={"outlets"}
            currentPage={this.props.currentPage}
            total={this.state.total}
            limit={this.props.currentLimit}
          />
        </div>

        <Footer />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let currentPage = state.page.outlets
    ? state.page.outlets.currentPage
    : state.page.default.currentPage

  let currentLimit = state.paginationLimit.outlets
    ? state.paginationLimit.outlets.currentLimit
    : 12

  return { currentPage, currentLimit }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateFilterElement: (filter) => dispatch(updateFilterElement(filter)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Outlets)
