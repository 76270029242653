import React, { Component } from "react"
import { Redirect } from "react-router-dom"
import axios from "axios"
import { connect } from "react-redux"
import moment from "moment"

import { query_generate } from "./../../utilities/_query_generate"
import { has_selected_filter } from "./../../utilities/_has_selected_filter"
import { relational_filter_list } from "./../../utilities/_filter_list_builder"

import TabNavs from "./components/tabNavs"
import KYCRiecevied from "./components/tabs/kycReceived"
import KYCReceivedRejected from './components/tabs/kycReceived__reject'
import PendingProcessing from "./components/tabs/pendingProcessing"
import Processed from "./components/tabs/processed"

import FilterElement from "./../layout/FilterElement"

import processed_img from "./../../img/kyc/tabs/processed_img.png"
import pending_processing_img from "./../../img/kyc/tabs/pending_processing_img.png"
import received_kyc_img from "./../../img/kyc/tabs/received_kyc_img.png"

import processed_active_img from "./../../img/kyc/tabs/processed_active_img.png"
import pending_processing_active_img from "./../../img/kyc/tabs/pending_processing_active_img.png"
import received_kyc_active_img from "./../../img/kyc/tabs/received_kyc_active_img.png"

import "./style.css"
import Footer from "../Footer/Footer"

class KycTracking extends Component {
  constructor(props) {
    super(props)

    this.state = {
      received_kyc_tab_nav: null,
      pending_processing_tab_nav: null,
      processed_tab_nav: null,

      status: {
        agency_user: {
          api: "api/kyc/v1/closed-leads",
          query: [
            { value: "fresh", label: "Fresh" }
          ],
          query_reject: [
            { value: "rejected", label: "Reject" }
          ],
          body: undefined,
        },

        bkash_user: {
          api: "api/kyc/v1/list",
          query: [
            { value: "bkash_qc_received", label: "bKash QC Received" }
          ],
          body: undefined,
        },

        "bkash-super-user": {
          api: "api/kyc/v1/list",
          query: [
            {
              value: "bkash_super_user_received",
              label: "Bkash Super User Received",
            },
          ],
          body: undefined,
        },
      },
    }
  }

  componentDidMount() {

    const localUserType = JSON.parse(localStorage.getItem("currUserType"))
    this.set_data_set()
    if (localUserType === "agency_user") {
      this.set_data_set__reject()
    }
  }

  componentDidUpdate(prevProps) {
    const localUserType = JSON.parse(localStorage.getItem("currUserType"))
    if (
      this.props.endDate !== prevProps.endDate ||
      this.props.startDate !== prevProps.startDate ||
      this.props.filterElement !== prevProps.filterElement
    ) {
      this.set_data_set()
      if (localUserType === "agency_user") {
        this.set_data_set__reject()
      }
    }
  }

  set_data_set = () => {
    const localUserType = JSON.parse(localStorage.getItem("currUserType"))

    let query = query_generate([
      {
        key: "team",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.team
            : [],
      },
      {
        key: "cluster_manager",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.managers
            : [],
      },
      {
        key: "agency",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.agency
            : [],
      },
      {
        key: "tab",
        values:
          this.state.status[`${localUserType}`]["query"] !== undefined
            ? this.state.status[`${localUserType}`]["query"]
            : [],
      },
    ])

    axios
      .get(
        `https://${process.env.REACT_APP_API_URL}/${
        this.state.status[`${localUserType}`]["api"]
        }?start_time=${moment(this.props.startDate).format(
          "YYYY-MM-DD"
        )}&end_time=${moment(this.props.endDate).format(
          "YYYY-MM-DD"
        )}&page_index=1&page_size=10${query !== "" ? "&" + query : query}`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
          },
        }
      )
      .then((res) => {
        return localUserType === "bkash-super-user"
          ? this.setState({
            received_kyc_tab_nav: {
              image: received_kyc_img,
              active_image: received_kyc_active_img,
              name: "Agency Processing",
              count: res.data.received_count,
              index: 1
            },
            pending_processing_tab_nav: {
              image: pending_processing_img,
              active_image: pending_processing_active_img,
              name: "bKash Processing",
              count: res.data.pending_processing_count,
              index: 2
            },
            processed_tab_nav: {
              image: processed_img,
              active_image: processed_active_img,
              name: "Processed",
              count: res.data.processed_count,
              index: 3
            },
          })
          : this.setState({
            received_kyc_tab_nav: {
              image: received_kyc_img,
              active_image: received_kyc_active_img,
              name: "Submitted",
              count: res.data.received_count,
              index: 1
            },
            pending_processing_tab_nav: {
              image: pending_processing_img,
              active_image: pending_processing_active_img,
              name: "Pending Processing",
              count: res.data.pending_processing_count,
              index: 2
            },
            processed_tab_nav: {
              image: processed_img,
              active_image: processed_active_img,
              name: "Processed",
              count: res.data.processed_count,
              index: 3
            },
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }




  set_data_set__reject = () => {
    const localUserType = JSON.parse(localStorage.getItem("currUserType"))

    let query = query_generate([
      {
        key: "team",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.team
            : [],
      },
      {
        key: "cluster_manager",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.managers
            : [],
      },
      {
        key: "agency",
        values:
          this.props.filterElement.relational !== undefined
            ? this.props.filterElement.relational.agency
            : [],
      },
      {
        key: "tab",
        values:
          this.state.status[`${localUserType}`]["query_reject"] !== undefined
            ? this.state.status[`${localUserType}`]["query_reject"]
            : [],
      },
    ])

    axios
      .get(
        `https://${process.env.REACT_APP_API_URL}/${
        this.state.status[`${localUserType}`]["api"]
        }?start_time=${moment(this.props.startDate).format(
          "YYYY-MM-DD"
        )}&end_time=${moment(this.props.endDate).format(
          "YYYY-MM-DD"
        )}&page_index=1&page_size=10${query !== "" ? "&" + query : query}`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
          },
        }
      )
      .then((res) => {
        return localUserType === "agency_user" ?
          this.setState({
            received_rejected_kyc_tab_nav: {
              image: received_kyc_img,
              active_image: received_kyc_active_img,
              name: "Rejected By bKash",
              count: res.data.rejected_count,
              index: 4
            }
          }) : null
      })
      .catch((err) => {
        console.log(err)
      })
  }



  create_filter_elements = (filter, type) =>
    filter.map((item, index) => (
      <FilterElement
        key={index}
        {...item}
        selectedValue={this.props.filterElement}
        type={type}
      />
    ))

  async remove_all_filter() {
    await this.props.updateFilterElement({
      kyc_received: {
        relational: {},
        non_relational: {},
      },
    })
  }

  render() {
    const localUserToken = JSON.parse(localStorage.getItem("currUserToken"))
    if (!localUserToken) return <Redirect to="/signin" />
    return (
      <div className="main-container">
        <div
          className={
            JSON.parse(localStorage.getItem("currUserType")) !== "agency_user"
              ? "kyc"
              : "full-width-kyc"
          }
        >
          <div className="page-settings">
            {JSON.parse(localStorage.getItem("currUserType")) !==
              "agency_user" &&
              this.props.filterHide && (
                <div
                  className="filter-container"
                  ref={(node) => {
                    this.node = node
                  }}
                >
                  <div className="header">
                    <div className="heading">Select Filter</div>
                    {has_selected_filter(this.props.filterElement) ? (
                      <div className="clear">
                        <button
                          className="active-button"
                          onClick={() => this.remove_all_filter()}
                        >
                          Clear All
                        </button>
                      </div>
                    ) : (
                        <div className="clear">
                          <button
                            className="inactive-button"
                            onClick={() => this.remove_all_filter()}
                            disabled
                          >
                            Clear All
                        </button>
                        </div>
                      )}
                  </div>
                  <div className="body">
                    {this.create_filter_elements(
                      relational_filter_list("kyc"),
                      "relational"
                    )}
                  </div>
                </div>
              )}
          </div>

          <TabNavs
            tab_navs={JSON.parse(localStorage.getItem("currUserType")) === 'agency_user' ? [
              this.state.received_kyc_tab_nav,
              this.state.received_rejected_kyc_tab_nav,
              this.state.pending_processing_tab_nav,
              this.state.processed_tab_nav
            ] : [
                this.state.received_kyc_tab_nav,
                this.state.pending_processing_tab_nav,
                this.state.processed_tab_nav,
              ]}
          />
        </div>

        {parseInt(this.props.selectedTab) === 1 ? (
          <KYCRiecevied
            tabs_set_data_set={this.set_data_set}
            heading={
              this.state.received_kyc_tab_nav
                ? this.state.received_kyc_tab_nav.name
                : "..."
            }
          />
        ) : parseInt(this.props.selectedTab) === 4 ? (
          <KYCReceivedRejected
            tabs_set_data_set={this.set_data_set}
            heading={
              this.state.received_rejected_kyc_tab_nav
                ? this.state.received_rejected_kyc_tab_nav.name
                : "..."
            }
          />) : parseInt(this.props.selectedTab) === 2 ? (
            <PendingProcessing
              tabs_set_data_set={this.set_data_set}
              heading={
                this.state.pending_processing_tab_nav
                  ? this.state.pending_processing_tab_nav.name
                  : "..."
              }
            />
          ) : parseInt(this.props.selectedTab) === 3 ? (
            <Processed
              tabs_set_data_set={this.set_data_set}
              heading={
                this.state.processed_tab_nav
                  ? this.state.processed_tab_nav.name
                  : "..."
              }
            />
          ) : null}

        <Footer />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let startDate = state.calendar.kyc
    ? state.calendar.kyc.startDate
    : state.calendar.default.startDate
  let endDate = state.calendar.kyc
    ? state.calendar.kyc.endDate
    : state.calendar.default.endDate
  let filterElement = state.filterElement.kyc
    ? state.filterElement.kyc
    : state.filterElement.default
  let filterHide = state.filter.filterHide
  let selectedTab = state.selectedTab.selectedTabIndex

  return { startDate, endDate, filterElement, filterHide, selectedTab }
}

export default connect(mapStateToProps)(KycTracking)
