import React from "react"
import "./Footer.styles.scss"

const Footer = ({ single }) => {
  return (
    <div className={`web-footer-container ${single}`}>
      <p>AI and Analytics by Intelligent Machines</p>
    </div>
  )
}

export default Footer
