import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authAction'

class SignOut extends Component {

    componentDidMount() {
        this.props.signOut()
    }

    render() {
        const localUserToken = JSON.parse(localStorage.getItem('currUserToken'))
        if (!localUserToken) return <Redirect to="/" />
        return <div className="main-container">

        </div>
    }
}

const mapDispatchToProps = (dispatch) => ({ signOut: () => dispatch(signOut()) })

export default connect(null, mapDispatchToProps)(SignOut)