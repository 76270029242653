import React, { Component } from 'react'
import ReactModal from 'react-modal'
import axios from 'axios'

import comment_modal_corner from './../../../../img/kyc/commentModal/comment_modal_corner.svg'

import './../../styles/actions/commentModal.css'

export default class CallModal extends Component {
    constructor() {
        super();
        this.state = {
            showModal: false,
            comment: null
        };


        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    handleOpenModal() {
        this.setState({ showModal: true });
    }

    handleCloseModal() {
        this.setState({ showModal: false });
    }

    handleChange(text) {
        this.setState({
            comment: text
        })
    }

    render() {
        return (
            <div className={`modal-container ${this.props.classNamePrefix}-comment-modal-container`} >
                <button
                    className={`${this.props.classNamePrefix}-open-modal-button`}
                    onClick={this.handleOpenModal}
                >
                    Reject
                </button>

                <ReactModal
                    isOpen={this.state.showModal}
                    contentLabel="onRequestClose Example"
                    onRequestClose={this.handleCloseModal}
                    shouldCloseOnOverlayClick={true}
                    className="comment-modal"
                >
                    <img
                        src={comment_modal_corner}
                        alt="right-top"
                        className="modal-corner"
                    />
                    <div className="row header">
                        <div className="col s12 l12">
                            <div className="heading">Rejection Reason
                                <span className="sub-heading">
                                    Select or type reason for rejection
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row body">
                        <div className="input-field">
                            <i className="material-icons prefix">create</i>
                            <label htmlFor="comment">Comment (Optional)</label>
                            <input
                                type="text"
                                id="comment"
                                onChange={(e) => this.handleChange(e.target.value)}
                            />

                            {/* <label class="pure-material-textfield-outlined">
                                <input placeholder=" " />
                                <span>Textfield</span>
                            </label> */}
                        </div>


                        <div className="button-group">
                            <button
                                className="reject-comment-cancel"
                                onClick={() => this.handleCloseModal()}
                            >
                                Cancel
                            </button>
                            <button
                                className="reject-comment-submit"
                                onClick={() => {
                                    const localUserType = JSON.parse(localStorage.getItem('currUserType'))

                                    axios
                                        .post(`https://${process.env.REACT_APP_API_URL}/${this.props.status[`${localUserType}`]["reject"]["api"]}/${this.props.id}/actions`,
                                            {
                                                ...this.props.status[`${localUserType}`]["reject"]["body"],
                                                'comment': this.state.comment
                                            },
                                            {
                                                headers: { "Authorization": "Bearer " + JSON.parse(localStorage.getItem('currUserToken')) }
                                            }
                                        ).then(() => this.props.update_action_taken('Rejected')
                                        ).then(() => this.props.pending_processing_set_data_set === 'none' ?
                                            null
                                            : (this.props.update_action_taken(null), this.props.pending_processing_set_data_set())
                                        ).then(() => this.handleCloseModal()
                                        )
                                }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </ReactModal>
            </ div >
        )
    }
}