const search__array_boject = (array_object, key, value) => {
    if (array_object === undefined || array_object === null) return false
    for (var i = 0; i < array_object.length; i++) {
        if (parseInt(array_object[i][`${key}`]) === parseInt(value) || array_object[i][`${key}`] === value) {
            return true;
        }
    }
    return false
}

export { search__array_boject }