import React, { Component } from "react"
import "./ViewLogModal.styles.scss"
import EscapeOutside from "react-escape-outside"
import moment from "moment"

import close_green_corner from "./_image/close_green_corner.svg"
import close_button from "./_image/close_button.svg"

const Modal = ({ handleClose, show, children }) => {
  return (
    <div className={show ? "modal display-block" : "modal display-none"}>
      <EscapeOutside onEscapeOutside={handleClose}>
        <div className="wrapper-modal">
          <section className="modal-main">{children}</section>
        </div>
      </EscapeOutside>
    </div>
  )
}

class ViewLogModal extends Component {
  render() {
    return (
      <section className="viewlogfse-modal">
        <Modal show={this.props.show} handleClose={this.props.handleHide}>
          <div className="modal-header">
            <div className="headline">
              <div className="main-headline">Account Log</div>
              <div className="sub-headline">History of Changes</div>
            </div>
            <div
              className="close-button-corner"
              onClick={this.props.handleHide}
            >
              <img className="corner" src={close_green_corner} alt="close" />
              <img className="cross" src={close_button} alt="close" />
            </div>
          </div>
          <div className="modal-body">
            <div className="basic">
              <div className="log-table">
                <table>
                  <thead>
                    <tr>
                      <th className="date">Date</th>
                      <th className="action">Action</th>
                      <th className="changes-by">Changes Made By</th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.props.logs.map((log, index) => (
                      <tr key={index}>
                        <td className="r-date">
                          {moment(log.date_field).format("Do MMM YYYY")}
                        </td>
                        <td className="r-action">{log.action}</td>
                        <td className="r-changes">
                          {log.changed_made_by_name}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal>
      </section>
    )
  }
}

export default ViewLogModal
