import React, { Component } from 'react'
import axios from 'axios'

import CommentModal from './commentModal'

import './../../styles/actions/acceptReject.css'

class AcceptReject extends Component {
    constructor(props) {
        super(props)

        this.state = {
            action_taken: null,

            loading: false,

            status: {
                "agency_user": {
                    "approve": {
                        "api": "api/kyc/v1/",
                        "query": undefined,
                        "body": { "action_type": "kyc_agency_approved" },
                    },
                    "reject": {
                        "api": "api/kyc/v1/",
                        "query": undefined,
                        "body": { "action_type": "kyc_agency_rejected" },
                    }
                },

                "bkash_user": {
                    "approve": {
                        "api": "api/kyc/v1/",
                        "query": undefined,
                        "body": { "action_type": "kyc_bkash_approved" },
                    },
                    "reject": {
                        "api": "api/kyc/v1/",
                        "query": undefined,
                        "body": { "action_type": "kyc_bkash_rejected" },
                    }
                }
            },

            permission: {
                'agency_user': ['kyc_received'],
                'bkash_user': ['kyc_bkash_received', 'kyc_bkash_cs_rejected']
            },

            show: {
                'agency_user': {
                    'kyc_agency_approved': 'Approved',
                    'kyc_agency_rejected': 'Rejected'
                },
                'bkash_user': {
                    'kyc_bkash_approved': 'Approved',
                    'kyc_bkash_rejected': 'Rejected'
                }
            }
        }
    }

    update_action_taken = (action_taken) => this.setState({ action_taken: action_taken })

    render() {
        const localUserType = JSON.parse(localStorage.getItem('currUserType'))
        return (
            <div className={`${this.props.classNamePrefix}-accept-reject-button-container`}>
                {!this.state.permission[`${localUserType}`].includes(this.props.status) ?
                    <button className="action-taken" disabled>{this.state.show[`${localUserType}`][`${this.props.status}`]}</button>
                    : <>
                        <CommentModal
                            pending_processing_set_data_set={this.props.pending_processing_set_data_set}
                            id={this.props.id}
                            status={this.state.status}
                            update_action_taken={this.update_action_taken}
                            classNamePrefix={this.props.classNamePrefix}
                        />
                        <div className={`${this.props.classNamePrefix}-accept-button-container`}>
                            <button
                                onClick={() => {
                                    this.setState({
                                        loading: true
                                    })

                                    axios
                                        .post(`https://${process.env.REACT_APP_API_URL}/${this.state.status[`${localUserType}`]["approve"]["api"]}/${this.props.id}/actions`,
                                            {
                                                ...this.state.status[`${localUserType}`]["approve"]["body"]
                                            },
                                            {
                                                headers: { "Authorization": "Bearer " + JSON.parse(localStorage.getItem('currUserToken')) }
                                            }
                                        ).then(() => this.setState({
                                            action_taken: 'Approved'
                                        })).then(() => this.props.pending_processing_set_data_set === 'none' ?
                                            null
                                            : this.setState({ action_taken: null }, () => this.props.pending_processing_set_data_set())
                                        )
                                }}
                                className={this.state.loading ? `${this.props.classNamePrefix}-accept-button-loading` : `${this.props.classNamePrefix}-accept-button`}

                                disabled={this.state.loading}

                            >
                                Approve
                </button>
                        </div>
                    </>
                }
            </div>
        )
    }
}

export default AcceptReject

