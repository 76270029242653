import React, { Component } from 'react'

import './../../styles/actions/status.css'
import { string_trim } from './../../../../utilities/_string_trim'

class Status extends Component {
    render() {
        console.log(this.props.status)
        return (
            <div className="status-container">
                <span className={`status ${this.props.status}`}>
                    {string_trim(this.props.status).split(" ").reverse()[0].toLowerCase() === 'activated' ? 'Activated' :
                        string_trim(this.props.status).split(" ").reverse()[0].toLowerCase() === 'approved' ? 'Approved' :
                            'Rejected'}
                </span>
            </div>
        )
    }
}

export default Status