const initState = {
    selectedMenuIndex: 0
}

const selectedMenuReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SELECTED_MENU_UPDATE':
            return {
                ...state,
                ...action.selectedMenuIndex
            }
        default:
            return state
    }
}

export default selectedMenuReducer