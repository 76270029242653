import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';

import moment from 'moment'

export default class CustomPieChart extends PureComponent {
    state = {
        activeIndex: 0,
    };

    onPieEnter = (data, index) => {
        this.setState({
            activeIndex: index,
        });
    };


    renderActiveShape = (props) => {
        const {
            cx,
            cy,
            innerRadius,
            outerRadius,
            startAngle,
            endAngle,
            fill,
            value
        } = props;

        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 2}
                    outerRadius={outerRadius + 3}
                    fill={fill}
                />
                <text
                    x={cx}
                    y={cy - 10}
                    dy={8}
                    textAnchor="middle"
                    fontFamily="Roboto"
                    fill="#434364"
                    fontSize="18px"
                >
                    {`${value}%`}
                </text>

                <text
                    x={cx}
                    y={cy + 12}
                    dy={8} textAnchor="middle"
                    fontFamily="Roboto"
                    fill="#707070"
                    fontSize="12px"
                >
                    {moment(this.props.date).format('ddd, MMM DD') === moment(new Date()).format('ddd, MMM DD') ?
                        `Today` : `${moment(this.props.date).format('ll')}`
                    }
                </text>
            </g>
        );
    };

    render() {
        return (
            <div style={{ width: '100%', height: 200 }}>
                <ResponsiveContainer>
                    <PieChart>
                        <Pie
                            activeIndex={this.state.activeIndex}
                            activeShape={this.renderActiveShape}
                            data={this.props.data.slice(0, this.props.data.length - 1)}

                            innerRadius={50}
                            outerRadius={60}
                            fill="#8884d8"
                            dataKey="percentage"
                            onMouseEnter={this.onPieEnter}
                        >
                            {
                                this.props.data.map((entry, index) => <Cell key={`cell-${index}`} fill={this.props.COLORS[index % this.props.COLORS.length]} />)
                            }
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
            </div>
        );
    }
}
