const sub_query_generate = (key, values) => {
    if (values.length <= 0 || values === null) return ''
    let sub_query = values.map(item => item.value).join(`&${key}=`)

    return `${key}=${sub_query}`
}

const query_generate = (queries) => {
    if (queries) {
        let query_strings = []
        let query_string = ''
        let clean_queries = queries.filter(item => item.values && item.values.length > 0)


        if (clean_queries.length > 0) {
            for (var i = 0; i < clean_queries.length; i++) {
                query_strings.push(sub_query_generate(clean_queries[i].key, clean_queries[i].values))
            }

            query_string = query_strings.join('&')
            return query_string
        }

        return query_string
    }
    return ''
}


export { query_generate }