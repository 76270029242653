const has_selected_filter = (filterElement) => {
    const { relational, non_relational } = filterElement

    if (Object.keys(relational).length > 0) {
        const relational_elements = Object.keys(relational)

        for (let relational_element_index = 0; relational_element_index < relational_elements.length; relational_element_index++) {
            if (relational[relational_elements[relational_element_index]].length > 0) return true
        }
    }

    if (Object.keys(non_relational).length > 0) {
        const non_relational_elements = Object.keys(non_relational)

        for (let non_relational_element_index = 0; non_relational_element_index < non_relational_elements.length; non_relational_element_index++) {
            if (non_relational[non_relational_elements[non_relational_element_index]].length > 0) return true
        }
    }

    return false
}


export { has_selected_filter }