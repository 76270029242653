import React, { Component } from "react"
import { connect } from "react-redux"
import { signIn } from "../../store/actions/authAction"
import { Redirect } from "react-router-dom"

import sign_in_img from "./../../img/sign_in/sign_in.svg"
import Footer from "../Footer/Footer"

class SignIn extends Component {
  state = {
    email: "",
    password: "",
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.signIn(this.state)
  }
  render() {
    const { authToken } = this.props
    if (authToken && authToken.token && authToken.type === "bkash_user")
      return <Redirect to="/kyc" />
    if (authToken && authToken.token) return <Redirect to="/" />
    return (
      <div className="signIn main-container">
        <img src={sign_in_img} alt="sign-in" />
        <form onSubmit={this.handleSubmit} className="white">
          <h5 className="grey-text text-darken-3">Log In</h5>
          <div className="input-field">
            <i className="material-icons prefix">account_circle</i>
            <label htmlFor="username">User Name</label>
            <input type="text" id="username" onChange={this.handleChange} />
          </div>

          <div className="input-field">
            <i className="material-icons prefix">vpn_key</i>
            <label htmlFor="password">Password</label>
            <input type="password" id="password" onChange={this.handleChange} />
          </div>

          <div className="">
            <button className="submit-form btn pink lighten-1 z-depth-0">
              Sign In
            </button>
            {/* <div className="red-text center">
                            {authError ? <p>{authError}</p> : null}
                        </div> */}
          </div>
        </form>
        <Footer single="signin" />
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    authToken: state.auth.authToken,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
