import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'

import { updateFilterElement } from './../../store/actions/filterElementAction'
import { query_generate } from './../../utilities/_query_generate'
import { search__array_boject } from './../../utilities/_search__array_object'

import './styles/FilterElement.css'
import './../../checkbox.css'

class FilterElement extends Component {
    constructor(props) {
        super(props)

        this.state = {
            value_state: [],
            selectedFilter: [],
            searchText: null,
        }
    }

    componentDidMount() {
        this.setState({
            selectedFilter: this.props.selectedValue[this.props.type][this.props.filterElementName] ? this.props.selectedValue[this.props.type][this.props.filterElementName] : []
        }, () => this.get_value())


    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedValue !== this.props.selectedValue) {
            this.setState({
                selectedFilter: this.props.selectedValue[this.props.type][this.props.filterElementName] ? this.props.selectedValue[this.props.type][this.props.filterElementName] : []
            }, () => this.get_value())
        }
    }


    get_value = () => {
        let query_pre_process = this.props.parentFilter.map(item => ({
            'key': item,
            'values': this.props.selectedValue[this.props.type][item]
        }))
        let query = query_generate(query_pre_process)
        let api_link = `${this.props.api_link}${query !== '' ? this.props.queryParameterAddWith + query : query}`

        return axios
            .get(`https://${process.env.REACT_APP_API_URL}${api_link}`, {
                headers: { "Authorization": "Bearer " + JSON.parse(localStorage.getItem('currUserToken')) }
            })
            .then((res) => this.setState({
                value_state: res.data.data.map(item => {
                    return {
                        'value': item.id,
                        'label': item.name
                    }
                }),
            })
            ).catch(err => err)
    }

    handleDependentFilter_updateSelectedValue = async (filterElementName, selectedValue) =>
        this.props.updateFilterElement({
            [this.props.page]: {
                ...this.props.selectedValue,
                [this.props.type]: {
                    ...this.props.selectedValue[this.props.type],
                    [filterElementName]: selectedValue
                }
            }
        })

    handleCheckbox = (target) => {
        let current_item = { 'value': target.value, 'label': target.name }
        let current_selected_items = this.state.selectedFilter

        this.setState({
            selectedFilter: search__array_boject(this.state.selectedFilter, 'value', target.value) ?
                current_selected_items.filter(item => item.value !== target.value)
                : [...current_selected_items, current_item]
        }, () => this.handleDependentFilter_updateSelectedValue(
            this.props.filterElementName,
            this.state.selectedFilter
        ).then(() => this.props.childFilter.map(item =>
            this.props.updateFilterElement({
                [this.props.page]: {
                    ...this.props.selectedValue,
                    [this.props.type]: {
                        ...this.props.selectedValue[this.props.type],
                        [item]: []
                    }
                }
            })
        ))
        )
    }

    async remove_filter() {
        await this.props.updateFilterElement({
            [this.props.page]: {
                ...this.props.selectedValue,
                [this.props.type]: {
                    ...this.props.selectedValue[this.props.type],
                    [this.props.filterElementName]: []
                },
            }
        })
    }

    render() {
        return (
            <div className="filter-section">
                <div className="filter-header">
                    <div className="filter-heading">
                        {this.props.section_heading}
                    </div>
                    <div className="filter-clear">
                        {this.props.selectedValue[this.props.type][this.props.filterElementName] && this.props.selectedValue[this.props.type][this.props.filterElementName].length > 0 ?
                            < button className="active-button" onClick={() => this.remove_filter()}>
                                Clear
                            </button>
                            : < button className="inactive-button" disabled>
                                Clear
                            </button>
                        }
                    </div>
                </div>

                <div className="options">
                    {this.state.value_state.map((option, index) =>
                        <label
                            style={{
                                'display': `${this.state.searchText && !option.label.toUpperCase().includes(this.state.searchText.toUpperCase()) ? 'none' : 'flex'}`
                            }}
                            key={index}
                            className="filter-option"
                        >
                            <input
                                className="checkbox-pink filled-in"
                                type="checkbox"
                                value={option.value}
                                name={option.label}
                                onChange={(e) => {
                                    this.handleCheckbox(e.target)
                                }}
                                checked={
                                    this.props.selectedValue[this.props.type][this.props.filterElementName]
                                    && search__array_boject(
                                        this.props.selectedValue[this.props.type][this.props.filterElementName],
                                        'value',
                                        option.value
                                    )
                                }
                            />
                            <span className="option-label">
                                {option.label}
                            </span>
                        </label>
                    )}
                </div>
            </div>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        updateFilterElement: (filter) => dispatch(updateFilterElement(filter))
    }
}


export default connect(null, mapDispatchToProps)(FilterElement)