import React, { Component } from "react"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"

import moment from "moment"

import Report from "./components/report"

import { has_selected_filter } from "./../../utilities/_has_selected_filter"
import { relational_filter_list } from "./../../utilities/_filter_list_builder"

import FilterElement from "./../layout/FilterElement"
import Calendar from "./../layout/Calendar"

import { updateFilterElement } from "../../store/actions/filterElementAction"

import "./style.css"
import "./../layout/styles/filter.css"
import Footer from "../Footer/Footer"

const outlet_calls_by_merchandisersList = [
  {
    name: "Raw Data Report",
    url: `/api/reports/v1/raw-data-report`,
    filename: "raw_data",
    file_type: "xlsx",
  },
  {
    name: "MDO Performance Report",
    url: `/api/reports/v1/mdo-wise-report`,
    filename: "mdo_data",
    file_type: "xlsx",
  },
  {
    name: "Merchant Call Report",
    url: `/api/reports/v2/merchant-call-report`,
    filename: "merchant_call",
    file_type: "xlsx",
  },
  {
    name: "MDO Covid Phase Performance Report",
    url: `/api/reports/v2/mdo-wise-covid-report`,
    filename: "mdo_covid_phase_data",
    file_type: "xlsx",
  },
  // , {
  //     'name': 'Covid Report',
  //     'url': `/api/reports/v2/mdo-wise-covid-report`,
  //     'filename': 'covid-data',
  //     'file_type': 'xlsx'
  // }
]

class Reports extends Component {
  constructor(props) {
    super(props)

    this.state = {
      startDate: null,
      endDate: null,
      currentPage: null,
      filterElement: null,
    }
  }

  componentDidMount() {
    this.set_data_set()
  }

  componentDidUpdate(prevProps) {
    if (this.props.filterElement !== prevProps.filterElement)
      this.set_data_set()
  }

  set_data_set = () =>
    this.setState({
      currentPage: this.props.currentPage,
      filterElement: this.props.filterElement,
    })

  create_filter_elements = (filter, type) =>
    filter.map((item, index) => (
      <FilterElement
        key={index}
        {...item}
        selectedValue={this.props.filterElement}
        type={type}
      />
    ))

  async remove_all_filter() {
    await this.props.updateFilterElement({
      reports: {
        relational: {},
        non_relational: {},
      },
    })
  }

  render() {
    const localUserToken = JSON.parse(localStorage.getItem("currUserToken"))
    if (!localUserToken) return <Redirect to="/signin" />
    return (
      <div className="main-container">
        <div
          className={
            JSON.parse(localStorage.getItem("currUserType")) !== "agency_user"
              ? "reports"
              : "full-width-reports"
          }
        >
          <div className="page-header">
            <div className="page-heading">
              Reports
              <div className="page-sub-heading">
                {moment(this.props.startDate).format("ddd, MMM DD")} to{" "}
                {moment(this.props.endDate).format("ddd, MMM DD")}
              </div>
            </div>
            <div className="page-settings">
              {JSON.parse(localStorage.getItem("currUserType")) !==
                "agency_user" &&
                this.props.filterHide && (
                  <div className="filter-container">
                    <div className="header">
                      <div className="heading">Select Filter</div>
                      {has_selected_filter(this.props.filterElement) ? (
                        <div className="clear">
                          <button
                            className="active-button"
                            onClick={() => this.remove_all_filter()}
                          >
                            Clear All
                          </button>
                        </div>
                      ) : (
                        <div className="clear">
                          <button
                            className="inactive-button"
                            onClick={() => this.remove_all_filter()}
                            disabled
                          >
                            Clear All
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="body">
                      {this.create_filter_elements(
                        relational_filter_list("reports"),
                        "relational"
                      )}
                    </div>
                  </div>
                )}
              <div className="info">
                <Calendar
                  page={"reports"}
                  startDate={this.props.startDate}
                  endDate={this.props.endDate}
                />
              </div>
            </div>
          </div>

          <div className="card-row row">
            {outlet_calls_by_merchandisersList.map((item, index) => (
              <div key={index} className="card-col col l4 m6 s12">
                <Report
                  report={item}
                  currentPage={this.state.currentPage}
                  filterElement={this.state.filterElement}
                  // actions={this.props.actions}
                />
              </div>
            ))}
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  let startDate = state.calendar.reports
    ? state.calendar.reports.startDate
    : state.calendar.default.startDate
  let endDate = state.calendar.reports
    ? state.calendar.reports.endDate
    : state.calendar.default.endDate
  let currentPage = state.page.currentPage
  let filterElement = state.filterElement.reports
    ? state.filterElement.reports
    : state.filterElement.default
  let filterHide = state.filter.filterHide

  // console.log(actions)

  return { startDate, endDate, currentPage, filterElement, filterHide }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateFilterElement: (filter) => dispatch(updateFilterElement(filter)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports)
