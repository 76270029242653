const initState = {
    'default': {
        startDate: new Date(),
        endDate: new Date()
    }
}

const calendarReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CALENDAR_UPDATE':
            return {
                ...state,
                ...action.date
            }
        default:
            return state
    }
}

export default calendarReducer