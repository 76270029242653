import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment'

import { updateLocalAction } from '../../../store/actions/localActionAction'

import xlsx_img from './../../../img/reports_card/ic_XLS.svg'
import share_img from './../../../img/reports_card/share.svg'
import download_img from './../../../img/reports_card/download.svg'

import './../styles/report.css'

class Report extends Component {

    download_file = (startDate, endDate, api_link, filename, file_type) => {
        let body = {
            "start_time": moment(this.props.startDate).format('YYYY-MM-DD'),
            "end_time": moment(this.props.endDate).format('YYYY-MM-DD'),
            "team": this.props.filterElement
                && this.props.filterElement.relational
                && this.props.filterElement.relational.team ?
                this.props.filterElement.relational.team.map(item => item.value)
                : [],
            "cluster_manager": this.props.filterElement
                && this.props.filterElement.relational
                && this.props.filterElement.relational.managers ?
                this.props.filterElement.relational.managers.map(item => item.value)
                : [],
            "agency": this.props.filterElement
                && this.props.filterElement.relational
                && this.props.filterElement.relational.agency ?
                this.props.filterElement.relational.agency.map(item => item.value)
                : []
        }

        this.props.updateLocalAction({
            'reports': {
                ...this.props.actions,
                [filename]: 'downloading'
            }
        })
        axios
            .post(`https://${process.env.REACT_APP_API_URL}${api_link}`,
                {
                    ...body
                }

                , {
                    headers: { "Authorization": "Bearer " + JSON.parse(localStorage.getItem('currUserToken')) },
                    responseType: "blob"
                })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    `${filename}_${moment(startDate).format('YYYY-MM-DD')}_${moment(endDate).format('YYYY-MM-DD')}.${file_type}`
                );
                document.body.appendChild(link);
                link.click();
            })
            .then(() => this.props.updateLocalAction({
                'reports': {
                    ...this.props.actions,
                    [filename]: null
                }
            }))
            .catch(() => {
                this.props.updateLocalAction({
                    'reports': {
                        ...this.props.actions,
                        [filename]: null
                    }
                })
            })
    }
    render() {
        return (
            <>
                <div className="report">
                    <div className="heading">
                        <img className="xlsx-icon" src={xlsx_img} alt="icon" />
                        <div className="info">
                            <div className="title">
                                {this.props.report.name}
                            </div>
                            <div className="description">
                                From {moment(this.props.startDate).format('ddd, MMM DD')} to {moment(this.props.endDate).format('ddd, MMM DD')}
                            </div>
                        </div>

                    </div>
                    <div className="action">
                        <div className="button-group" >
                            <button
                                onClick={() => this.download_file(
                                    this.props.startDate,
                                    this.props.endDate,
                                    this.props.report.url,
                                    this.props.report.filename,
                                    this.props.report.file_type
                                )}
                                className="share-button"
                            >

                                <img src={share_img} alt="share" />Share
                            </button>

                            <button
                                onClick={() => this.download_file(
                                    this.props.startDate,
                                    this.props.endDate,
                                    this.props.report.url,
                                    this.props.report.filename,
                                    this.props.report.file_type
                                )}
                                className="download-button"
                            >

                                <img src={download_img} alt="download" />Download
                            </button>
                        </div>
                        {this.props.actions
                            && this.props.actions[this.props.report.filename]
                            && <div
                                className="progress"
                                style={{
                                    'borderRadius': "0px 0px 8px 8px",
                                    'margin': '0px'
                                }}
                            >
                                <div className="indeterminate"></div>
                            </div>}
                    </div>
                </div>


            </>

        )
    }
}

const mapStateToProps = (state) => {
    let startDate = state.calendar.reports ? state.calendar.reports.startDate : state.calendar.default.startDate
    let endDate = state.calendar.reports ? state.calendar.reports.endDate : state.calendar.default.endDate
    let actions = state.localAction.reports ? state.localAction.reports : state.localAction.default

    return (
        { startDate, endDate, actions }
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateLocalAction: (action) => dispatch(updateLocalAction(action))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Report)