import React, { Component } from 'react'
import axios from 'axios'
import moment from 'moment'

import PieChart from './pieChart'
import LoadingCard from './loadingCard'

import './../styles/rightGraphCard.css'


const REASON_COLORS = ['#E2136E', '#FF8400', '#00CFFF', '#8BB100'];
const resistance_causes = {
    'card_heading': 'Causes of Resistance',
    'state': 'resistance_causes_state',
    'api_link': `api/reports/v1/reason-based-report`,
}

export default class ResistanceCauseCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data_fetching: false
        }
    }

    componentDidMount() {
        this.set_data_set()
    }

    componentDidUpdate(prevProps) {
        if (this.props.date !== prevProps.date
            || this.props.filterElement !== prevProps.filterElement)
            this.set_data_set()
    }

    set_data_set = () => {
        let body = {
            "start_time": moment(this.props.date).format('YYYY-MM-DD'),
            "end_time": moment(this.props.date).format('YYYY-MM-DD'),
            "team": this.props.filterElement.team ? this.props.filterElement.team.map(item => item.value) : [],
            "cluster_manager": this.props.filterElement.managers ? this.props.filterElement.managers.map(item => item.value) : [],
            "agency": this.props.filterElement.agency ? this.props.filterElement.agency.map(item => item.value) : []
        }

        this.setState({
            data_fetching: true
        })

        axios
            .post(`https://${process.env.REACT_APP_API_URL}/${resistance_causes['api_link']}`,
                {
                    ...body
                }, {
                headers: { "Authorization": "Bearer " + JSON.parse(localStorage.getItem('currUserToken')) }
            })
            .then((res) => this.setState({
                [`${resistance_causes['state']}`]: res.data,
                data_fetching: false
            })
            ).catch(() => this.setState({
                data_fetching: false
            }))
    }

    render() {
        return (<div className="card-col col s12 l6">
            {
                this.state.data_fetching ?
                    <LoadingCard card_heading={`${resistance_causes.card_heading}`} />
                    : <div className="card right-graph-card">
                        <div className="card-title">
                            {resistance_causes.card_heading}
                        </div>
                        {this.state[`${resistance_causes['state']}`]
                            && <div className="card-body">
                                <div className="chart">
                                    <PieChart
                                        date={this.props.date}
                                        data={this.state.resistance_causes_state}
                                        COLORS={REASON_COLORS} />
                                </div>
                                <div className="data-table">
                                    {this.state[`${resistance_causes['state']}`].map((item, index) =>
                                        <div
                                            className="data-container"
                                            key={"div-" + index}
                                        >
                                            <div
                                                className="rect"
                                                style={{ "background": `${REASON_COLORS[index]}` }}
                                            >

                                            </div>
                                            <div className="data">
                                                <div className="value">{item.percentage} %</div>
                                                <div className="tag">{item.reason}</div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                    </div>
            }
        </div>)
    }
}