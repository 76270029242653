import React, { Component } from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import moment from "moment"

import MDOCount from "./components/mdoCountCard"
import MDOPerformance from "./components/mdoPerformanceCard"
import CallOverview from "./components/callOverviewCard"
import BinaryGraphCard from "./components/binaryGraphCard"
import ResistanceCauseCard from "./components/resistanceCauseCard"
import KYCTracking from "./components/kycTracking"
import AgencyPerformance from "./components/agencyPerformance"
import Footer from "../Footer/Footer"

import { has_selected_filter } from "./../../utilities/_has_selected_filter"
import { relational_filter_list } from "./../../utilities/_filter_list_builder"

import CalendarDatePicker from "./../layout/CalendarDatePicker"
import FilterElement from "./../layout/FilterElement"

import { updateFilterElement } from "../../store/actions/filterElementAction"

import "./style.css"
import "./../layout/styles/filter.css"

class Dashboard extends Component {
  create_filter_elements = (filter, type) =>
    filter.map((item, index) => (
      <FilterElement
        key={index}
        {...item}
        selectedValue={this.props.filterElement}
        type={type}
      />
    ))

  async remove_all_filter() {
    await this.props.updateFilterElement({
      dashboard: {
        relational: {},
        non_relational: {},
      },
    })
  }

  render() {
    const localUserToken = JSON.parse(localStorage.getItem("currUserToken"))
    if (!localUserToken) return <Redirect to="/signin" />

    return (
      <>
        {JSON.parse(localStorage.getItem("currUserType")) !== "agency_user" &&
          this.props.filterHide && (
            <div className="filter-container">
              <div className="header">
                <div className="heading">Select Filter</div>
                {has_selected_filter(this.props.filterElement) ? (
                  <div className="clear">
                    <button
                      className="active-button"
                      onClick={() => this.remove_all_filter()}
                    >
                      Clear All
                    </button>
                  </div>
                ) : (
                    <div className="clear">
                      <button
                        className="inactive-button"
                        onClick={() => this.remove_all_filter()}
                        disabled
                      >
                        Clear All
                    </button>
                    </div>
                  )}
              </div>
              <div className="body">
                {this.create_filter_elements(
                  relational_filter_list("dashboard"),
                  "relational"
                )}
              </div>
            </div>
          )}
        <div className="main-container">
          <div
            className={
              JSON.parse(localStorage.getItem("currUserType")) !== "agency_user"
                ? "dashboard"
                : "full-width-dashboard"
            }
          >
            <div className="page-header">
              <div className="page-heading">
                Dashboard
                <div className="page-sub-heading">
                  {moment(this.props.date).format("ddd, MMM DD")}
                </div>
              </div>
              <div className="page-settings">
                <div className="info">
                  <CalendarDatePicker
                    page={"dashboard"}
                    date={this.props.date}
                  />
                </div>
              </div>
            </div>

            <div className="card-row row">
              <MDOCount
                date={this.props.date}
                filterElement={this.props.filterElement}
              />

              <MDOPerformance
                date={this.props.date}
                filterElement={this.props.filterElement}
              />

              <CallOverview
                date={this.props.date}
                filterElement={this.props.filterElement}
              />
            </div>

            <div className="card-row row">
              <KYCTracking
                date={this.props.date}
                filterElement={this.props.filterElement}
              />
            </div>
            <div className="card-row row">
              <BinaryGraphCard
                date={this.props.date}
                filterElement={this.props.filterElement}
              />

              <ResistanceCauseCard
                date={this.props.date}
                filterElement={this.props.filterElement}
              />
            </div>

            <div className="card-row row">
              <AgencyPerformance
                date={this.props.date}
                filterElement={this.props.filterElement}
              />
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  let date = state.calendarDatePicker.dashboard
    ? state.calendarDatePicker.dashboard.date
    : state.calendarDatePicker.default.date

  let filterElement = state.filterElement.dashboard
    ? state.filterElement.dashboard
    : state.filterElement.default

  let filterHide = state.filter.filterHide

  return { date, filterElement, filterHide }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateFilterElement: (filter) => dispatch(updateFilterElement(filter)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
