import React, { Component, lazy, Suspense } from "react"
import { Button, Icon, Divider } from "react-materialize"
import "./UserManagementSection.styles.scss"
import { connect } from "react-redux"
import { changeTo } from "../../../../store/actions/adminTableSelectAction"

const FSETable = lazy(() => import("../FSETable/FSETable"))
const MDOTable = lazy(() => import("../MDOTable/MDOTable"))

class UserManagementSection extends Component {
  changeActive = (to) => {
    if (to !== this.state.active_group) {
      this.setState({
        active_group: to,
      })
    }
  }

  render() {
    return (
      <div className="user-management">
        <div className="title-buttons-container">
          <div className="title">
            <h2>User Mangement</h2>
          </div>

          <div className="buttons-group">
            <Button
              className={`im-btn fse-btn ${
                this.props.active_table === "fse" ? `active` : `inactive`
                }`}
              onClick={() => this.props.changeTo("fse")}
              node="button"
              style={{
                marginRight: "0px",
              }}
              waves="light"
            >
              FSE
              <Icon left>person</Icon>
            </Button>
            <Button
              className={`im-btn mdo-btn ${
                this.props.active_table === "mdo" ? `active` : `inactive`
                }`}
              onClick={() => this.props.changeTo("mdo")}
              node="button"
              style={{
                marginRight: "0px",
              }}
              waves="light"
            >
              MDO
              <Icon left>people</Icon>
            </Button>
          </div>
        </div>

        <Divider />
        <Suspense fallback={<div>Loading ....</div>}>
          {this.props.active_table === "mdo" && <MDOTable />}
          {this.props.active_table === "fse" && <FSETable />}
        </Suspense>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  active_table: state.selectedAdminTable.active_table,
})

const mapDispatchToProps = (dispatch) => ({
  changeTo: (table) => dispatch(changeTo(table)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagementSection)
