import axios from 'axios'

export const signIn = (credentials) => {
    return (dispatch, getState) => {
        const user_token = axios.post(`https://${process.env.REACT_APP_API_URL}/api/auth/token`, {
            username: credentials.username,
            password: credentials.password
        })

        axios
            .all([user_token])
            .then(axios.spread((...responses) => {
                const token = responses[0].data.access
                localStorage.setItem('currUserToken', JSON.stringify(token))

                axios.get(`https://${process.env.REACT_APP_API_URL}/api/users/api/v1/me`, {
                    headers: { "Authorization": "Bearer " + token }
                })
                    .then(res => localStorage.setItem('currUserType', JSON.stringify(res.data.user_type)))
                    .then(() => {
                        const type = JSON.parse(localStorage.getItem('currUserType'))
                        dispatch({
                            type: 'LOGIN_SUCCESS',
                            auth: {
                                token,
                                type
                            }
                        })
                    })

            })).catch(err => {
                dispatch({ type: 'LOGIN_ERROR', err })
            })
    }
}

export const signOut = () => {
    return (dispatch, getState) => {
        let promise = new Promise((resolve, reject) => resolve(localStorage.removeItem('currUserToken')))
        promise.then(() => window.location.reload())

        dispatch({ type: 'SIGNOUT_SUCCESS' })
    }
}