const initState = {
    'default': {}
}

const uplaodRejectedReducer = (state = initState, action) => {
    switch (action.type) {
        case 'UPLOAD_REJECTION_UPDATE':
            return {
                ...state,
                ...action.actions
            }
        default:
            return state
    }
}

export default uplaodRejectedReducer