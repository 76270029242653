import React, { Component } from 'react'
import axios from 'axios'
import moment from 'moment'

import { query_generate } from './../../../utilities/_query_generate'

import LoadingCard from './loadingCard'

import call_overview_img from './../../../img/dashboard_card/call_overview.svg'

import './../styles/callOverviewCard.css'


const call_overview = {
    'card_heading': 'Call Overview',
    'state': 'call_overview_state',
    'api_link': `api/reports/v2/call-type-wise-report`,
    'conrner_right_top_img': call_overview_img
}

export default class CallOverviewCard extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        this.set_data_set()
    }

    componentDidUpdate(prevProps) {
        if (this.props.date !== prevProps.date
            || this.props.filterElement !== prevProps.filterElement)
            this.set_data_set()
    }

    set_data_set = () => {
        let query_pre_process = [{
            'key': 'team',
            'values': this.props.filterElement.relational !== undefined ? this.props.filterElement.relational.team : []
        }, {
            'key': 'cluster_manager',
            'values': this.props.filterElement.relational !== undefined ? this.props.filterElement.relational.managers : []
        }, {
            'key': 'agency',
            'values': this.props.filterElement.relational !== undefined ? this.props.filterElement.relational.agency : []
        }]
        let query = query_generate(query_pre_process)

        this.setState({
            data_fetching: true
        })
        axios
            .get(`https://${process.env.REACT_APP_API_URL}/${call_overview['api_link']}?date=${moment(this.props.date).format('YYYY-MM-DD')}${query !== '' ? '&' + query : ''}`,
                {
                    headers: { "Authorization": "Bearer " + JSON.parse(localStorage.getItem('currUserToken')) }
                })
            .then((res) => this.setState({
                [`${call_overview['state']}`]: res.data,
                data_fetching: false
            })
            ).catch(() => this.setState({
                data_fetching: false
            }))
    }

    render() {
        return (
            <div className="card-col col s12 m6 l4 ">
                {this.state.data_fetching ?
                    <LoadingCard card_heading={`${call_overview.card_heading}`} />
                    : <div className="card data-card">
                        {call_overview.conrner_right_top_img !== 'none' ?
                            <img
                                className="img card-corner"
                                src={call_overview.conrner_right_top_img}
                                alt="card-corner"
                            />
                            : null
                        }
                        <div className="card-title">
                            {call_overview.card_heading}
                        </div>
                        {this.state[`${call_overview['state']}`]
                            && this.state[`${call_overview['state']}`].current
                            && <div className="card-body">
                                <div className="new-leads">
                                    <div className="value">
                                        {this.state[`${call_overview['state']}`].current.new_leads}
                                    </div>
                                    <div className="tag">
                                        New Leads
                                        </div>
                                </div>

                                <div className="lead-closed">
                                    <div className="value">
                                        {this.state[`${call_overview['state']}`].current.closed}
                                    </div>
                                    <div className="tag">
                                        Leads Closed
                                        </div>
                                </div>

                                <div className="followed-up">
                                    <div className="value">
                                        {this.state[`${call_overview['state']}`].current.followup_leads}
                                    </div>
                                    <div className="tag">
                                        Leads Followed-up
                                        </div>
                                </div>

                                <div className="merchant-served">
                                    <div className="value">
                                        {this.state[`${call_overview['state']}`].current.regular}
                                    </div>
                                    <div className="tag">
                                        Existing Merchant
                                        </div>
                                </div>




                                {/* Temporary */}
                                <div
                                    className="new-leads"
                                    style={{
                                        // 'background': '#f2f2f2',
                                        // 'padding': '10px',
                                        // 'borderRadius': '4px'
                                    }}
                                >
                                    <div
                                        className="value"
                                    >
                                        {this.state[`${call_overview['state']}`].current.merchant_contacted}
                                    </div>
                                    <div className="tag">
                                        Merchants Contacted
                            </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }
}