const initState = {
  default: {
    currentLimit: 10,
  },
}

const paginationLimitReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_PAGE_LIMIT":
      return {
        ...state,
        [action.page]: { currentLimit: action.currentLimit },
      }

    default:
      return state
  }
}

export default paginationLimitReducer
