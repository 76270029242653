const initState = {
    'default': {
        relational: {},
        non_relational: {}
    }
}

const filterElementReducer = (state = initState, action) => {
    switch (action.type) {
        case 'FILTER_ELEMENT_UPDATE':
            return {
                ...state,
                ...action.filterElement
            }
        default:
            return state
    }
}

export default filterElementReducer