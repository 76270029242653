import React, { Component } from "react"
import { connect } from "react-redux"
import { changeTo } from "../../../../store/actions/adminTableSelectAction"
import "./FSEManagement.styles.scss"
import { Link } from "react-router-dom"
import { Button, Icon, Divider } from "react-materialize"
import Pagination from "../../../layout/Pagination"
import MDOTableList from "./components/MDOTableList/MDOTableList"
import EditModal from "./components/EditModal/EditModal"
import ViewLogModal from "./components/ViewLogModal/ViewLogModal"
import ReplaceFSEModal from "./components/ReplaceFSEModal/ReplaceFSEModal"
import axios from "axios"
import Footer from "../../../Footer/Footer"

export class FSEManagement extends Component {
  constructor() {
    super()

    this.state = {
      total: null,
      limit: null,

      showViewLogModal: false,
      showReplaceFSEModal: false,
      showEditModal: false,
      data_fetching: true,
      image: null,

      leave_day: "",
      leave_day_id: "",

      name: "",
      phone: "",
      agency: "",
      agency_id: "",
      id: "",
      mdo_list: [],
      logs: [],
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = (id) => {
    //GETTING FSE DETAILS
    axios
      .get(
        `https://${process.env.REACT_APP_API_URL}/api/admin_panel/v1/fse-list?id=${
        id ? id : this.props.match.params.id
        }`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
          },
        }
      )
      .then((res) => {
        if (res.data.data[0].leave_day[0] === "Sunday")
          this.setState({ leave_day_id: "101" })
        if (res.data.data[0].leave_day[0] === "Monday")
          this.setState({ leave_day_id: "102" })
        if (res.data.data[0].leave_day[0] === "Tuesday")
          this.setState({ leave_day_id: "103" })
        if (res.data.data[0].leave_day[0] === "Wednesday")
          this.setState({ leave_day_id: "104" })
        if (res.data.data[0].leave_day[0] === "Thursday")
          this.setState({ leave_day_id: "105" })
        if (res.data.data[0].leave_day[0] === "Friday")
          this.setState({ leave_day_id: "106" })
        if (res.data.data[0].leave_day[0] === "Saturday")
          this.setState({ leave_day_id: "107" })

        return this.setState({
          image: res.data.data[0].image,
          name: res.data.data[0].name,
          phone: res.data.data[0].contact_number,
          agency: res.data.data[0].reporting_agency__name,
          agency_id: res.data.data[0].reporting_agency__id,
          leave_day: res.data.data[0].leave_day,
          id: res.data.data[0].id,
        })
      })
      .catch((err) => {
        console.log(err)
      })

    //GETTIING FSE MDO's
    axios
      .get(
        `https://${process.env.REACT_APP_API_URL}/api/admin_panel/v1/mdo-list?page_index=${
        this.props.currentPage
        }&page_size=${parseInt(this.props.currentLimit)}&fse=${
        id ? id : this.props.match.params.id
        }`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
          },
        }
      )
      .then((res) => {
        this.setState({
          total: res.data.count,
          limit: res.data.limit,
          mdo_list: res.data.data,
        })
      })
      .catch((err) => {
        this.setState({
          data_fetching: false,
        })
        console.log((err) => `error fetching mdo's under fse ${err}`)
      })

    //GETTING FSE LOGS
    axios
      .get(
        `https://${process.env.REACT_APP_API_URL}/api/users/api/v1/user-log?user_type=fse&user_id=${
        id ? id : this.props.match.params.id
        }`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
          },
        }
      )
      .then((res) => {
        this.setState({
          logs: res.data.data,
          data_fetching: false,
        })
      })
      .catch((err) => console.log(`error fetching log data ${err}`))
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.currentPage !== prevProps.currentPage ||
      this.props.currentLimit !== prevProps.currentLimit
    )
      this.getData()
  }

  handleUpdate = () => {
    this.getData()
  }
  handleReplace = (id) => {
    this.getData(id)
    // let history = useHistory()
    // history.push(`/admin/fse/${id}`)
  }

  showEditModal = () => this.setState({ showEditModal: true })
  hideEditModal = () => this.setState({ showEditModal: false })

  showViewLogModal = () => this.setState({ showViewLogModal: true })
  hideViewLogModal = () => this.setState({ showViewLogModal: false })

  showReplaceFSEModal = () => this.setState({ showReplaceFSEModal: true })
  hideReplaceFSEModal = () => this.setState({ showReplaceFSEModal: false })

  render() {
    const {
      image,
      name,
      phone,
      agency,
      // id,
      data_fetching,
      mdo_list,
    } = this.state
    return (
      <div className="main-container">
        {data_fetching ? (
          <p>Loading...</p>
        ) : (
            <>
              <div className="header-bg">
                <div className="header-container">
                  <div
                    onClick={() => this.props.changeTo("fse")}
                    className="back-btn"
                  >
                    <Link to="/admin">
                      <Icon className="back-icn">chevron_left</Icon>
                    </Link>
                  </div>
                  <div className="fse-details">
                    <div className="img-container">
                      {image === null ? (
                        <img
                          className="user-image"
                          src={`https://png.pngitem.com/pimgs/s/506-5067022_sweet-shap-profile-placeholder-hd-png-download.png`}
                          alt={name}
                        />
                      ) : (
                          <img className="user-image" src={image} alt={name} />
                        )}
                    </div>
                    <div className="name-phone">
                      <div className="name-tag">
                        <h2 className="name">{name}</h2>
                        <p className="tag">FSE</p>
                      </div>
                      <div className="phone-copy">
                        <h3>{phone}</h3>
                        {/* <Link>
                        <Icon>file_copy</Icon>
                      </Link> */}
                      </div>
                    </div>
                    <div className="agency">
                      <div className="agency-icon">
                        <Icon small>person</Icon>
                      </div>
                      <div className="agency-name-label">
                        <p className="agency-label">Agency</p>
                        <h3 className="agency-name">{agency}</h3>
                      </div>
                    </div>
                  </div>
                  <div className="btn-all">
                    <div className="btn-group">
                      <Button
                        className="im-primary"
                        onClick={() => this.showEditModal()}
                      >
                        Edit
                    </Button>
                      <EditModal
                        handleHide={this.hideEditModal}
                        show={this.state.showEditModal}
                        image={this.state.image}
                        id={this.state.id}
                        name={this.state.name}
                        phone={this.state.phone}
                        agency={this.state.agency}
                        agency_id={this.state.agency_id}
                        leave_day={this.state.leave_day}
                        leave_day_id={this.state.leave_day_id}
                        handleSubmit={this.handleUpdate}
                      />
                      <Button
                        className="im-secondary"
                        onClick={this.showViewLogModal}
                      >
                        View Log
                    </Button>
                      <ViewLogModal
                        handleHide={this.hideViewLogModal}
                        show={this.state.showViewLogModal}
                        id={this.state.id}
                        name={this.state.name}
                        phone={this.state.phone}
                        agency={this.state.agency}
                        logs={this.state.logs}
                      />
                    </div>
                    <div className="replace-btn">
                      <Button
                        className="im-secondary"
                        onClick={this.showReplaceFSEModal}
                      >
                        <Icon left>code</Icon>Replace FSE
                    </Button>
                      <ReplaceFSEModal
                        handleHide={this.hideReplaceFSEModal}
                        show={this.state.showReplaceFSEModal}
                        id={this.state.id}
                        name={this.state.name}
                        phone={this.state.phone}
                        agency={this.state.agency}
                        handleSubmit={this.handleReplace}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mdo-display">
                <h2>MDO's Under Management</h2>
                <p className="mdo-count">{this.state.total}</p>
              </div>

              <Divider />

              <div className="content">
                <Pagination
                  page={`fsemdomanage`}
                  currentPage={this.props.currentPage}
                  total={this.state.total}
                  limit={this.props.currentLimit}
                />

                <div className="worker-table">
                  <table>
                    <thead>
                      <tr>
                        <th className="name">MDO Name</th>
                        <th className="phone">MDO Phone Number</th>
                        <th className="agency">Spots</th>
                      </tr>
                    </thead>

                    <tbody>
                      {mdo_list.map((mdo) => (
                        <MDOTableList key={mdo.id} data={mdo} />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let currentLimit = state.paginationLimit.fsemdomanage
    ? state.paginationLimit.fsemdomanage.currentLimit
    : state.paginationLimit.default.currentLimit

  let currentPage = state.page.fsemdomanage
    ? state.page.fsemdomanage.currentPage
    : state.page.default.currentPage

  return { currentLimit, currentPage }
}

const mapDispatchToProps = (dispatch) => ({
  changeTo: (table) => dispatch(changeTo(table)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FSEManagement)
