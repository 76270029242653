import React, { Component } from 'react'
import axios from 'axios'
import moment from 'moment'

import { query_generate } from './../../../utilities/_query_generate'

import LoadingCard from './loadingCard'

import inc_img from './../../../img/dashboard_card/inc_img.svg'
import dec_img from './../../../img/dashboard_card/dec_img.svg'
import mdo_performance_img from './../../../img/dashboard_card/MDO_performance.svg'

import './../styles/mdoPerformanceCard.css'


const mdo_performance = {
    'card_heading': 'MDO Performance',
    'state': 'mdo_performance_state',
    'api_link': `api/reports/v2/mdo-performance-report`,
    'inc_img': inc_img,
    'dec_img': dec_img,
    'conrner_right_top_img': mdo_performance_img
}

export default class MDOPerformanceCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data_fetching: false
        }
    }

    componentDidMount() {
        this.set_data_set()
    }

    componentDidUpdate(prevProps) {
        if (this.props.date !== prevProps.date
            || this.props.filterElement !== prevProps.filterElement)
            this.set_data_set()
    }


    set_data_set = () => {
        let query_pre_process = [{
            'key': 'team',
            'values': this.props.filterElement.relational !== undefined ? this.props.filterElement.relational.team : []
        }, {
            'key': 'cluster_manager',
            'values': this.props.filterElement.relational !== undefined ? this.props.filterElement.relational.managers : []
        }, {
            'key': 'agency',
            'values': this.props.filterElement.relational !== undefined ? this.props.filterElement.relational.agency : []
        }]
        let query = query_generate(query_pre_process)

        this.setState({
            data_fetching: true
        })

        axios
            .get(`https://${process.env.REACT_APP_API_URL}/${mdo_performance['api_link']}?date=${moment(this.props.date).format('YYYY-MM-DD')}${query !== '' ? '&' + query : ''}`,
                {
                    headers: { "Authorization": "Bearer " + JSON.parse(localStorage.getItem('currUserToken')) }
                })
            .then((res) => this.setState({
                [`${mdo_performance['state']}`]: res.data,
                data_fetching: false
            })
            ).catch(() => this.setState({
                data_fetching: false
            }))
    }

    render() {
        return (
            <div className="card-col col s12 m6 l4 ">
                {this.state.data_fetching ?
                    <LoadingCard card_heading={`${mdo_performance.card_heading}`} />
                    : <div className="card data-card">
                        {mdo_performance.conrner_right_top_img !== 'none' ?
                            <img
                                className="img card-corner"
                                src={mdo_performance.conrner_right_top_img}
                                alt="card-corner"
                            />
                            : null
                        }
                        <div className="card-title">
                            {mdo_performance.card_heading}
                        </div>
                        {this.state[`${mdo_performance['state']}`]
                            && this.state[`${mdo_performance['state']}`].current
                            && <div className="card-body">
                                <div className="total-calls">
                                    <div className="value">
                                        {this.state[`${mdo_performance['state']}`].current.call_count}
                                    </div>
                                    <div className="tag">
                                        Total Calls
                            </div>
                                </div>

                                <div className="comparison">
                                    <div className="value">
                                        {
                                            this.state[`${mdo_performance['state']}`].deltas.call_count.with_last_week > 0 ?
                                                <img src={mdo_performance.inc_img} alt="inc" />
                                                : this.state[`${mdo_performance['state']}`].deltas.call_count.with_last_week < 0 ?
                                                    <img src={mdo_performance.dec_img} alt="dec" /> : null
                                        } {this.state[`${mdo_performance['state']}`].deltas.call_count.with_last_week > 0 ?
                                            this.state[`${mdo_performance['state']}`].deltas.call_count.with_last_week
                                            : this.state[`${mdo_performance['state']}`].deltas.call_count.with_last_week * -1
                                        }%
                            </div>
                                    <div className="tag">
                                        {
                                            this.state[`${mdo_performance['state']}`].deltas.call_count.with_last_week > 0 ?
                                                `More`
                                                : 'Less'
                                        } Than Last Week
                            </div>
                                </div>

                                <div className="active-mdo">
                                    <div className="value">
                                        {this.state[`${mdo_performance['state']}`].current.call_per_mdo}
                                    </div>
                                    <div className="tag">
                                        Calls/Active MDO
                            </div>
                                </div>

                                <div className="comparison">
                                    <div className="value">
                                        {
                                            this.state[`${mdo_performance['state']}`].deltas.call_rate.with_last_week > 0 ?
                                                <img
                                                    src={mdo_performance.inc_img}
                                                    alt="inc"
                                                />
                                                : this.state[`${mdo_performance['state']}`].deltas.call_rate.with_last_week < 0 ?
                                                    <img
                                                        src={mdo_performance.dec_img}
                                                        alt="dec"
                                                    />
                                                    : null
                                        } {this.state[`${mdo_performance['state']}`].deltas.call_rate.with_last_week > 0 ?
                                            this.state[`${mdo_performance['state']}`].deltas.call_rate.with_last_week
                                            : this.state[`${mdo_performance['state']}`].deltas.call_rate.with_last_week * -1
                                        }%
                            </div>
                                    <div className="tag">
                                        {
                                            this.state[`${mdo_performance['state']}`].deltas.call_rate.with_last_week > 0 ?
                                                `More`
                                                : 'Less'
                                        } Than Last Week
                            </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }
}