const relational_filter_list = (page) => {

    const relational_filter = [
        {
            'section_heading': 'Select Team Type',
            'api_link': '/api/organizations/api/v1/team',
            'parentFilter': [],
            'childFilter': ['managers', 'agency'],
            'placeholder': 'Search Team',
            'placeholderButtonLabel': 'Team',
            'dropDownButtonLabel': { 'singular': 'Team is Selected', 'plural': 'Teams are Selected' },
            'queryParameterAddWith': '',
            'page': page,
            'filterElementName': 'team',
        }, {
            'section_heading': 'Select Cluster Manager',
            'api_link': '/api/users/api/v1/list?user_type=cluster_manager',
            'parentFilter': ['team'],
            'childFilter': ['agency'],
            'placeholder': 'Search Cluster Manager',
            'placeholderButtonLabel': 'Cluster Manager',
            'dropDownButtonLabel': { 'singular': 'Cluster Manager is Selected', 'plural': 'Cluster Managers are Selected' },
            'queryParameterAddWith': '&',
            'page': page,
            'filterElementName': 'managers'
        }, {
            'section_heading': 'Select Agency',
            'api_link': '/api/organizations/api/v1/agency',
            'parentFilter': ['team', 'managers'],
            'childFilter': [],
            'placeholder': 'Search Agency',
            'placeholderButtonLabel': 'Agency',
            'dropDownButtonLabel': { 'singular': 'Agency is Selected', 'plural': 'Agencies are Selected' },
            'queryParameterAddWith': '?',
            'page': page,
            'filterElementName': 'agency'
        }
    ]

    return relational_filter
}



const non_relational_filter_list = (page) => {
    const non_relational_filter = [{
        'section_heading': 'Select Call',
        'api_link': '/api/calls/v1/call_outcome_list',
        'parentFilter': [],
        'childFilter': [],
        'placeholder': 'Search By Call Outcome',
        'placeholderButtonLabel': 'Select Call Outcome',
        'dropDownButtonLabel': { 'singular': 'Call Outcome is Selected', 'plural': 'Call Outcomes are Selected' },
        'queryParameterAddWith': '?',
        'page': 'calls',
        'filterElementName': 'call_outcome'
    }]
    return non_relational_filter
}

export { relational_filter_list, non_relational_filter_list }