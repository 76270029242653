const initState = {
    'default': {
        currentPage: 0
    }
}

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SET_PAGE':
            return {
                ...state,
                [action.page]: { currentPage: action.currentPage },
            }
        case 'NEXT_PAGE': {
            return {
                ...state,
                [action.page]: { currentPage: action.nextPage },
            }
        }
        case 'PRE_PAGE':
            return {
                ...state,
                [action.page]: { currentPage: action.prePage },
            }
        default:
            return state
    }
}

export default authReducer