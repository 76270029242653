import React, { Component } from 'react'
import moment from 'moment'
import { string_trim } from './../../../../../utilities/_string_trim'

import './../style/kyc-history-card.css'


class KYCHistoryCard extends Component {
    render() {
        const kyc_id = Object.keys(this.props.history)
        return (
            <div className="kyc-history-card">
                {this.props.history[kyc_id].map((item, index) =>
                    <div key={index} className="history">
                        <div className="date">
                            {moment(item.created).format('DD MMM ;')}
                            <br />
                            {moment(item.created).format('HH:mm')}
                        </div>
                        <div className="icon">
                            <i className="material-icons left">
                                {item.action_type.includes('received') ? 'done' :
                                    item.action_type.includes('rejected') ? 'thumb_down' :
                                        'thumb_up'}
                            </i>
                        </div>
                        <div className="result">
                            <div className="dept">{string_trim(item.actor__username || '...')}</div>
                            <div
                                className={string_trim(item.action_type).split(" ").reverse()[0].toLowerCase() || '...'}
                            >
                                {string_trim(item.action_type).split(" ").reverse()[0] || 'status'}
                            </div>
                        </div>
                        {index + 1 !== this.props.history[kyc_id].length ? <div className="line"></div> : <div className="dash-line"></div>}
                    </div>
                )
                }
            </div>
        )
    }
}

export default KYCHistoryCard