const initState = {
    'default': {}
}

const localActionReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOCAL_ACTION_UPDATE':
            return {
                ...state,
                ...action.actions
            }
        default:
            return state
    }
}

export default localActionReducer