import React, { Component } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import windowSize from "react-window-size"

import SignedInLinks from "./SignedInLinks"
import SignedOutLinks from "./SignedOutLinks"

import { updateFilterHide } from "./../../store/actions/filterAction"

import Logo from "./../../img/lg_nimontron.svg"

class Navbar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      init_show: this.props.windowWidth >= 1000 ? true : false,
      selectedMenuIndex: 0,
    }
  }

  updateSelectedMenu = (selectedMenuIndex) =>
    this.setState({
      selectedMenuIndex: selectedMenuIndex,
    })

  render() {
    const localUserToken = JSON.parse(localStorage.getItem("currUserToken"))
    return (
      <nav className="custom-nav">
        
        {localUserToken ? (
          <>
          <Link to="/" className="logo">
          <img className="logo-img" src={Logo} alt="logo" />
        </Link>
          <button
            className=""
            onClick={() => this.props.updateFilterHide("change")}
          >
            <i className="material-icons left">
              {this.props.filterHide ? "close" : "filter_list"}
            </i>
          </button>
          </>
        ) : null}

        <div className="custom-nav-wrapper">
          {localUserToken ? (
            <SignedInLinks
              selectedMenu={this.state.selectedMenuIndex}
              updateSelectedMenu={this.updateSelectedMenu}
            />
          ) : (
            <SignedOutLinks />
          )}
        </div>
      </nav>
    )
  }
}

const mapSTateToProps = (state) => {
  return {
    authError: state.auth.authError,
    authToken: state.auth.authToken,
    filterElements: state.filterElement,
    filterHide: state.filter.filterHide,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateFilterHide: (filterHide) => dispatch(updateFilterHide(filterHide)),
  }
}

export default connect(mapSTateToProps, mapDispatchToProps)(windowSize(Navbar))
