const INITIAL_STATE = {
  active_table: "mdo",
}

const adminTableSelectReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "CHANGE_TO":
      return {
        ...state,
        active_table: action.payload,
      }

    default:
      return state
  }
}

export default adminTableSelectReducer
