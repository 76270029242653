const initState = {
    selectedTabIndex: 1
}

const selectedTabReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SELECTED_TAB_UPDATE': {
            return {
                ...state,
                selectedTabIndex: action.selectedTabIndex
            }
        }
        default:
            return state
    }
}

export default selectedTabReducer