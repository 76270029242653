import React from "react"
import { Router, Switch, Route } from "react-router-dom"
import history from "./utilities/_history"

import Navbar from "./Components/layout/Navbar"

import Reports from "./Components/Reports"
import Dashboard from "./Components/Dashboard"
import Calls from "./Components/Calls"
import Outlets from "./Components/Outlets"
import OutletsDetail from "./Components/Outlets/components/OutletsDetail"
import KycTracking from "./Components/KycTracking"
import Details from "./Components/KycTracking/components/details"
import Admin from "./Components/Admin"
import FSEManagment from "./Components/Admin/pages/FSEManagement/FSEManagement"

import SignIn from "./Components/Auth/signIn"
import SignOut from "./Components/Auth/signOut"

import "./style.scss"

function App() {
  return (
    <Router history={history}>
      <div className="App">
        <Navbar />
      </div>
      <Switch>
        <Route exact path="/" component={Dashboard}></Route>
        <Route exact path="/reports" component={Reports}></Route>
        <Route exact path="/calls" component={Calls}></Route>
        <Route exact path="/outlets" component={Outlets}></Route>
        <Route exact path={`/outlets/:id`} component={OutletsDetail}></Route>
        <Route exact path="/kyc" component={KycTracking}></Route>
        <Route
          exact
          path="/kyc/received/:action/:id/:kyc"
          component={Details}
        ></Route>
        <Route exact path="/admin" component={Admin}></Route>
        <Route exact path="/admin/fse/:id" component={FSEManagment}></Route>

        <Route path="/signin" component={SignIn}></Route>
        <Route path="/signout" component={SignOut}></Route>
      </Switch>
    </Router>
  )
}

export default App
