import React, { Component } from 'react'
import axios from 'axios'
import moment from 'moment'

import { query_generate } from './../../../utilities/_query_generate'

import LoadingCard from './loadingCard'

import mdo_count_img from './../../../img/dashboard_card/MDO_count.svg'

import './../styles/mdoCountCard.css'

const mdo_count = {
    'card_heading': 'MDO Count',
    'state': 'mdo_count_state',
    'api_link': `api/reports/v2/active-mdo-report`,
    'conrner_right_top_img': mdo_count_img
}


export default class MDOCountCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data_fetching: false
        }
    }

    componentDidMount() {
        this.set_data_set()
    }

    componentDidUpdate(prevProps) {
        if (this.props.date !== prevProps.date
            || this.props.filterElement !== prevProps.filterElement)
            this.set_data_set()
    }


    set_data_set = () => {
        let query_pre_process = [{
            'key': 'team',
            'values': this.props.filterElement.relational !== undefined ? this.props.filterElement.relational.team : []
        }, {
            'key': 'cluster_manager',
            'values': this.props.filterElement.relational !== undefined ? this.props.filterElement.relational.managers : []
        }, {
            'key': 'agency',
            'values': this.props.filterElement.relational !== undefined ? this.props.filterElement.relational.agency : []
        }]
        let query = query_generate(query_pre_process)

        this.setState({
            data_fetching: true
        })

        axios
            .get(`https://${process.env.REACT_APP_API_URL}/${mdo_count['api_link']}?date=${moment(this.props.date).format('YYYY-MM-DD')}${query !== '' ? '&' + query : ''}`,
                {
                    headers: { "Authorization": "Bearer " + JSON.parse(localStorage.getItem('currUserToken')) }
                })
            .then((res) => this.setState({
                [`${mdo_count['state']}`]: res.data,
                data_fetching: false
            })
            ).catch(() => this.setState({
                data_fetching: false
            }))
    }

    render() {
        return (

            <div className="card-col col s12 m6 l4 ">
                {this.state.data_fetching ?
                    <LoadingCard card_heading={`${mdo_count.card_heading}`} />
                    : <div className="card data-card">
                        {mdo_count.conrner_right_top_img !== 'none' ?
                            <img
                                className="img card-corner"
                                src={mdo_count.conrner_right_top_img}
                                alt="card-corner"
                            />
                            : null
                        }
                        <div className="card-title">
                            {mdo_count.card_heading}
                        </div>
                        {this.state[`${mdo_count['state']}`]
                            && this.state[`${mdo_count['state']}`].current
                            && <div className="card-body">
                                <div className="registered">
                                    <div className="value">
                                        {this.state[`${mdo_count['state']}`].current.total_count}
                                    </div>
                                    <div className="tag">
                                        Registered
                            </div>
                                </div>

                                <div className="assigned">
                                    <div className="value">
                                        {this.state[`${mdo_count['state']}`].current.assigned_count}
                                    </div>
                                    <div className="tag">
                                        Assigned For The Day
                            </div>
                                </div>

                                <div className="active-today">
                                    <div className="value">
                                        {this.state[`${mdo_count['state']}`].current.attendance_count}
                                    </div>
                                    <div className="tag">
                                        Active Today
                            </div>
                                </div>

                                <div className="comparison">
                                    <div className="value">
                                        {this.state[`${mdo_count['state']}`].current.percentage}%
                            </div>
                                    <div className="tag">
                                        Active (%) of Total Assinged
                            </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }
}