import React, { Component } from "react"
import { connect } from "react-redux"

import { prePage, nextPage } from "./../../store/actions/paginationAction"
import { updateLimit } from "../../store/actions/paginationLimitAction"

class Pagination extends Component {
    render() {
        return this.props.total ? (
            <div className="pagination-container">
                <div className="limit">
                    <label>
                        View{"  "}
                        <input
                            className="amount-picker"
                            type="number"
                            value={this.props.limit}
                            onChange={(event) =>
                                this.props.updateLimit(this.props.page, event.target.value)
                            }
                        />
                        {"  "}
                        items per page
          </label>
                </div>
                <div className="current">
                    {this.props.currentPage * this.props.limit + 1}-
          {this.props.currentPage * this.props.limit + this.props.limit >
                        this.props.total
                        ? this.props.total
                        : this.props.currentPage * this.props.limit + this.props.limit}{" "}
                    of {this.props.total}
                </div>
                <div className="controller">
                    <ul>
                        {parseInt(this.props.currentPage) < 1 ? null : (
                            <li
                                className="waves-effect right-arrow"
                                onClick={() =>
                                    this.props.prePage(this.props.page, this.props.currentPage)
                                }
                            >
                                <i className="material-icons">chevron_left</i>
                            </li>
                        )}
                        <li>
                            <span>{this.props.currentPage + 1}</span>
                        </li>
                        {this.props.total <
                            this.props.currentPage * this.props.limit +
                            this.props.limit ? null : (
                                <li className="waves-effect left-arrow">
                                    <i
                                        className="material-icons"
                                        onClick={() =>
                                            this.props.nextPage(this.props.page, this.props.currentPage)
                                        }
                                    >
                                        chevron_right
                </i>
                                </li>
                            )}
                    </ul>
                </div>
            </div>
        ) : null
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        nextPage: (page, currentPage) => dispatch(nextPage(page, currentPage)),
        prePage: (page, currentPage) => dispatch(prePage(page, currentPage)),
        updateLimit: (page, currentLimit) =>
            dispatch(updateLimit(page, currentLimit)),
    }
}
export default connect(null, mapDispatchToProps)(Pagination)
