import React, { Component } from "react"
import { Redirect } from "react-router-dom"
import axios from "axios"
import moment from "moment"
import { connect } from "react-redux"
import Pagination from "../../../layout/Pagination"
import { string_uppercase } from "../../../../utilities/_string_uppercase"

import OutletCallLocation from "./components/outletLocation"
import LoadingCard from "./components/loading"

import image_not_found_img from "./../../../../img/image-not-found.png"
import mdo_img from "./../../../../img/calls_card/mdo.svg"
import phone_img from "./../../../../img/calls_card/phone.svg"
import event_img from "./../../../../img/calls_card/event.svg"
import bmcc_img from "./../../../../img/calls_card/bmcc.svg"
import spot_img from "./../../../../img/calls_card/spot.svg"

import lead_closed_img from "./../../../../img/calls_card/lead_closed.svg"
import lead_generated_img from "./../../../../img/calls_card/lead_generated.svg"
import existing_merchant_served_img from "./../../../../img/calls_card/existing_merchant_served.svg"
import lead_followed_up_img from "./../../../../img/calls_card/lead_followed_up_img.svg"

import lead_closed__card_corner_img from "./../../../../img/calls_card/lead_closed_card_corner.svg"
import lead_generated__card_corner_img from "./../../../../img/calls_card/lead_generated_card_corner.svg"
import existing_merchant_served__card_corner_img from "./../../../../img/calls_card/existing_merchant_served_card_corner.svg"
import lead_followed_up__card_corner_img from "./../../../../img/calls_card/lead_followed_up_card_corner_img.svg"

import "./style.css"
import Footer from "../../../Footer/Footer"

class OutletsDetail extends Component {
  constructor(props) {
    super(props)

    this.state = {
      outlet_basic_info: null,
      outlet_calls_history: null,
      outlet_total_calls: null,
      outlet_calls_limit: null,

      data_fetching: false,
    }
  }

  componentDidMount() {
    this.set_outlet_basic_info()
    this.set_outlet_calls_history()
  }

  set_outlet_basic_info() {
    axios
      .get(
        `https://${process.env.REACT_APP_API_URL}/api/outlets/v1/${this.props.match.params.id}`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
          },
        }
      )
      .then((res) => {
        return this.setState({
          outlet_basic_info: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  set_outlet_calls_history() {
    this.setState({
      data_fetching: true,
    })

    axios
      .get(
        `https://${process.env.REACT_APP_API_URL}/api/outlets/v1/${this.props.match.params.id}/calls?page_index=${this.props.currentPage}&page_size=12`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("currUserToken")),
          },
        }
      )
      .then((res) => {
        return this.setState({
          outlet_calls_history: res.data.data,
          outlet_total_calls: res.data.count,
          outlet_calls_limit: res.data.limit,

          data_fetching: false,
        })
      })
      .catch(() =>
        this.setState({
          data_fetching: false,
        })
      )
  }

  render() {
    const localUserToken = JSON.parse(localStorage.getItem("currUserToken"))
    if (!localUserToken) return <Redirect to="/signin" />

    return (
      <div className="main-container">
        <div className="outlet-detail-container">
          {this.state.outlet_basic_info && (
            <div className="row">
              <div className="col l6 s12">
                <div className="outlet-section section-left">
                  <img
                    src={
                      this.state.outlet_basic_info.image || image_not_found_img
                    }
                    alt="outlet"
                  />
                  <div className="outlet-description outlet-description--right">
                    <span className="outlet-name">
                      {string_uppercase(this.state.outlet_basic_info.name)}
                    </span>
                    <div className="outlet-phone">
                      {this.state.outlet_basic_info.wallet_no ? (
                        <>
                          <div className="value">
                            {this.state.outlet_basic_info.wallet_no}
                          </div>
                          <div className="tag">Merchant Wallet Number</div>
                        </>
                      ) : (
                        <>
                          <div className="value">
                            {
                              this.state.outlet_basic_info
                                .contact_person_phone_number
                            }
                          </div>
                          <div className="tag">Merchant Contact Number</div>
                        </>
                      )}
                    </div>
                    <span
                      className={
                        this.state.outlet_basic_info.outlet_type.toUpperCase() ===
                        "MICRO MERCHANT"
                          ? "blue-type"
                          : this.state.outlet_basic_info.outlet_type.toUpperCase() ===
                            "MERCHANT PLUS LITE B"
                          ? "navy-blue-type"
                          : this.state.outlet_basic_info.outlet_type.toUpperCase() ===
                            "MERCHANT PLUS LITE A"
                          ? "purpple-type"
                          : this.state.outlet_basic_info.outlet_type.toUpperCase() ===
                            "MEDIUM MERCHANT"
                          ? "olive-type"
                          : "green-type"
                      }
                    >
                      {string_uppercase(
                        this.state.outlet_basic_info.outlet_type
                      )}
                    </span>

                    <div className="outlet-call__mdo">
                      <div className="call">
                        <div className="value">
                          {this.state.outlet_basic_info.call_count}
                        </div>
                        <div className="tag">Total Calls To This Outlet</div>
                      </div>
                      <div className="mdo">
                        <div className="value">
                          {this.state.outlet_basic_info.mdo_count}
                        </div>
                        <div className="tag">MDOs Covered This Outlet</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col l6 s12">
                <div className="outlet-section section-right">
                  <div className="outlet-description outlet-description--left">
                    <div className="info">
                      <img src={event_img} alt="bmcc img" />
                      <div className="data">
                        Last Call Date & Time
                        <span>
                          {this.state.outlet_basic_info.last_call
                            ? moment(
                                this.state.outlet_basic_info.last_call
                              ).format("llll")
                            : "..."}
                        </span>
                      </div>
                    </div>

                    <div className="info">
                      <img src={bmcc_img} alt="bmcc img" />
                      <div className="data">
                        <span>
                          {this.state.outlet_basic_info.bmcc_id || "..."}
                        </span>
                        <span className="code-description bmcc-code-description">
                          {this.state.outlet_basic_info.bmcc__name || "..."}
                        </span>
                      </div>
                    </div>

                    <div className="info">
                      <img src={spot_img} alt="bmcc img" />
                      <div className="data">
                        <span>
                          {this.state.outlet_basic_info.spot_code_id || "..."}
                        </span>
                        <span className="code-description">
                          {this.state.outlet_basic_info.spot_code__name ||
                            "..."}
                        </span>
                      </div>
                    </div>
                  </div>
                  {this.state.outlet_basic_info.lat &&
                    this.state.outlet_basic_info.lng && (
                      <OutletCallLocation
                        location={[
                          this.state.outlet_basic_info.lat,
                          this.state.outlet_basic_info.lng,
                        ]}
                      />
                    )}
                </div>
              </div>
            </div>
          )}

          <div className="page-header">
            <div className="page-heading">
              Call History
              <div className="page-sub-heading">
                {this.state.outlet_total_calls} Calls Till Date
              </div>
            </div>
          </div>

          <Pagination
            page={"outlet_detail"}
            currentPage={this.props.currentPage}
            total={this.state.outlet_total_calls}
            limit={this.state.outlet_calls_limit}
          />

          <div className="outlet-call-cards">
            {this.state.data_fetching ? (
              <LoadingCard
              // card_heading={`${item.card_heading}`}
              />
            ) : (
              <div className="card-row row">
                {this.state.outlet_calls_history &&
                  this.state.outlet_calls_history.map((item, index) => (
                    <div className="card-col col l4 m6 s12">
                      <div className="card call-card" key={this.props.index}>
                        <img
                          className="img card-corner"
                          src={
                            item.call_outcome.toUpperCase() ===
                            "EXISTING MERCHANT SERVED"
                              ? existing_merchant_served__card_corner_img
                              : item.call_outcome.toUpperCase() ===
                                "LEAD GENERATED"
                              ? lead_generated__card_corner_img
                              : item.call_outcome.toUpperCase() ===
                                "LEAD FOLLOWED-UP"
                              ? lead_followed_up__card_corner_img
                              : lead_closed__card_corner_img
                          }
                          alt="card-corner"
                        />
                        <div className="row header">
                          <div className="col s12 l12">
                            <img
                              src={
                                item.call_outcome.toUpperCase() ===
                                "EXISTING MERCHANT SERVED"
                                  ? existing_merchant_served_img
                                  : item.call_outcome.toUpperCase() ===
                                    "LEAD GENERATED"
                                  ? lead_generated_img
                                  : item.call_outcome.toUpperCase() ===
                                    "LEAD FOLLOWED-UP"
                                  ? lead_followed_up_img
                                  : lead_closed_img
                              }
                              alt="outlet"
                            />

                            <div className="call-info">
                              Call Outcome
                              <span
                                className={
                                  item.call_outcome.toUpperCase() ===
                                  "EXISTING MERCHANT SERVED"
                                    ? "existing_merchant_served_info"
                                    : item.call_outcome.toUpperCase() ===
                                      "LEAD GENERATED"
                                    ? "lead_generated_info"
                                    : item.call_outcome.toUpperCase() ===
                                      "LEAD FOLLOWED-UP"
                                    ? "lead_followed_up_info"
                                    : "lead_closed_info"
                                }
                              >
                                {string_uppercase(item.call_outcome) || "..."}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col s12 l6">
                            <img src={mdo_img} alt="mdo img" />
                            <div className="call-info">
                              MDO Name
                              <span className="name">
                                {string_uppercase(item.field_force__name) ||
                                  "..."}
                              </span>
                            </div>
                          </div>

                          <div className="col s12 l6">
                            <img src={phone_img} alt="phone img" />
                            <div className="call-info">
                              MDO Phone
                              <span>
                                {item.field_force__whitelisting_number}
                              </span>
                            </div>
                          </div>

                          <div className="col s12 l6">
                            <img src={event_img} alt="event img" />
                            <div className="call-info">
                              Call Date & Time
                              <span>
                                {moment(item.zone_aware_upload_time).format(
                                  "llll"
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="col s12 l6">
                            <img src={spot_img} alt="spot img" />
                            <div className="call-info">
                              Spot Code
                              <span>{item.spot_code__code || "..."}</span>
                              <span className="code-description">
                                {item.spot_code__name || "..."}
                              </span>
                            </div>
                          </div>
                          <div className="col s12 l6">
                            <img src={bmcc_img} alt="bmcc img" />
                            <div className="call-info">
                              BMCC Code
                              <span>{item.bmcc__code || "..."}</span>
                              <span className="code-description bmcc-code-description">
                                {item.bmcc__name || "..."}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>

          <Pagination
            page={"outlet_detail"}
            currentPage={this.props.currentPage}
            total={this.state.outlet_total_calls}
            limit={this.state.outlet_calls_limit}
          />
        </div>
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let currentPage = state.page.outlet_detail
    ? state.page.outlet_detail.currentPage
    : state.page.default.currentPage
  return { currentPage }
}

export default connect(mapStateToProps, null)(OutletsDetail)
