import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import moment from 'moment'
import Dropzone from 'react-dropzone'

import { query_generate } from '../../../../utilities/_query_generate'
import { updateUploadAction } from '../../../../store/actions/uploadActionAction'
import { updateRejectedAction } from '../../../../store/actions/uploadRejectedAction'

import Table from './components/table'
import UploadConfirmationModal from './components/uploadConfirmationModal'

import Pagination from '../../../layout/Pagination'
import Calendar from '../../../layout/Calendar'

import { updateFilterElement } from '../../../../store/actions/filterElementAction';
import { updateFilterHide } from '../../../../store/actions/filterAction'

import upload_img from './../../../../img/kyc/upload/Approved.svg'
import rejection_img from './../../../../img/kyc/upload/Rejected.svg'
import celebrate_rejection_img from './../../../../img/kyc/upload/celebrate_rejection.png'
import celebrate_upload_img from './../../../../img/kyc/upload/celebrate_upload.svg'

import './styles/processed.css'

const table_col_bkash = [
    'Wallet Number', 'Outlet Name', 'Cluster Manager', 'Agency', 'Spot Code', 'Spot Code Name'
]

const table_col_agency = [
    'Wallet Number', 'Outlet Name', 'MDO Number', 'MDO Name', 'Spot Code', 'Spot Code Name'
]

class Processed extends Component {
    constructor(props) {
        super(props)

        this.state = {
            processed: null,
            total: null,
            limit: null,
            search_term: '',
            activationModalInfo: null,
            rejectionModalInfo: null,

            showActivationModal: false,
            showRejectionModal: false,

            status: {
                "agency_user": {
                    "api": "api/kyc/v1/list",
                    "query": [
                        { "value": "agency_processed", "label": "Agency Processed" }
                    ],
                    "body": undefined,
                },

                "bkash_user": {
                    "api": "api/kyc/v1/list",
                    "query": [
                        { "value": "bkash_qc_processed", "label": "bKash QC Processed" }
                    ],
                    "body": undefined,
                },

                "bkash-super-user": {
                    "api": "api/kyc/v1/list",
                    "query": [
                        { "value": "bkash_super_user_processed", "label": "bKash Super User Processed" }
                    ],
                    "body": undefined,
                }
            }
        }
    }

    componentDidMount() {
        this.set_data_set()
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.endDate !== prevProps.endDate
            || this.props.startDate !== prevProps.startDate
            || this.props.currentPage !== prevProps.currentPage
            || this.props.filterElement !== prevProps.filterElement
        ) this.set_data_set()
    }

    set_data_set = () => {
        const localUserType = JSON.parse(localStorage.getItem('currUserType'))

        let query = query_generate([{
            'key': 'team',
            'values': this.props.filterElement.relational !== undefined ? this.props.filterElement.relational.team : []
        }, {
            'key': 'cluster_manager',
            'values': this.props.filterElement.relational !== undefined ? this.props.filterElement.relational.managers : []
        }, {
            'key': 'agency',
            'values': this.props.filterElement.relational !== undefined ? this.props.filterElement.relational.agency : []
        }, {
            'key': 'tab',
            'values': this.state.status[`${localUserType}`]["query"] !== undefined ? this.state.status[`${localUserType}`]["query"] : []
        }])


        axios
            .get(`https://${process.env.REACT_APP_API_URL}/${this.state.status[`${localUserType}`]["api"]}?start_time=${moment(this.props.startDate).format('YYYY-MM-DD')}&end_time=${moment(this.props.endDate).format('YYYY-MM-DD')}&page_index=${this.props.currentPage}&page_size=10${query !== '' ? '&' + query : query}&search_term=${this.state.search_term}`,
                {
                    headers: { "Authorization": "Bearer " + JSON.parse(localStorage.getItem('currUserToken')) }
                })
            .then((res) => {
                return this.setState({
                    processed: res.data.data,
                    total: res.data.count,
                    limit: res.data.limit
                })
            })
            .then(() => this.props.tabs_set_data_set())
            .catch(() => console.log('do nothing...'))
    }

    uploadFile(file) {
        console.log(file.name)
        var formdata = new FormData();
        formdata.append("file", file);

        this.props.updateUploadAction({
            'processed': {
                ...this.props.actions,
                'upload': file.name
            }
        })

        axios
            .post(`https://${process.env.REACT_APP_API_URL}/api/kyc/v1/activation`,
                formdata
                , {
                    headers: { "Authorization": "Bearer " + JSON.parse(localStorage.getItem('currUserToken')) }
                }).then(res => this.setState({
                    showActivationModal: true,
                    activationModalInfo: res.data
                })
                ).then(() => this.props.updateUploadAction({
                    'processed': {
                        ...this.props.actions,
                        'upload': null
                    }
                })
                ).then(() => this.set_data_set()
                ).catch(() => this.props.updateUploadAction({
                    'processed': {
                        ...this.props.actions,
                        'upload': null
                    }
                }))
    }



    uploadRejectionFile(file) {

        var formdata = new FormData();
        formdata.append("file", file);

        this.props.updateRejectedAction({
            'processed': {
                ...this.props.actions,
                'upload': file.name
            }
        })

        axios
            .post(`https://${process.env.REACT_APP_API_URL}/api/kyc/v1/rejection`,
                formdata
                , {
                    headers: { "Authorization": "Bearer " + JSON.parse(localStorage.getItem('currUserToken')) }
                }).then(res => this.setState({
                    showRejectionModal: true,
                    rejectionModalInfo: res.data
                })
                ).then(() => this.props.updateRejectedAction({
                    'processed': {
                        ...this.props.actions,
                        'upload': null
                    }
                })
                ).then(() => this.set_data_set()
                ).catch(() => this.props.updateRejectedAction({
                    'processed': {
                        ...this.props.actions,
                        'upload': null
                    }
                }))
    }

    handleCloseActivationModal = () =>
        this.setState({
            showActivationModal: false,
            activationModalInfo: null
        });

    handleCloseRejectionModal = () =>
        this.setState({
            showRejectionModal: false,
            rejectionModalInfo: null
        });


    render() {
        return (
            <>{this.state.activationModalInfo ?
                <UploadConfirmationModal
                    handleCloseModal={this.handleCloseActivationModal}
                    showModal={this.state.showActivationModal}
                    modalInfo={this.state.activationModalInfo}
                    type='activation'
                    image={celebrate_upload_img}
                />
                : null
            }{this.state.rejectionModalInfo ?
                <UploadConfirmationModal
                    handleCloseModal={this.handleCloseRejectionModal}
                    showModal={this.state.showRejectionModal}
                    modalInfo={this.state.rejectionModalInfo}
                    type='rejection'
                    image={celebrate_rejection_img}
                />
                : null
                }
                <div className={JSON.parse(localStorage.getItem('currUserType')) !== "agency_user" ? "processed" : "full-width-processed"}>
                    <div className="page-header">
                        <div className="page-heading-search">
                            <div className="page-heading">
                                {this.props.heading}
                                <div className="page-sub-heading">
                                    {moment(this.props.startDate).format('ddd, MMM DD')}  to {moment(this.props.endDate).format('ddd, MMM DD')}
                                </div>
                            </div>
                            <input
                                type="text"
                                className="search_outlet"
                                placeholder="Search by Wallet Number"
                                onChange={(e) => this.setState({
                                    search_term: e.target.value
                                }, () => this.set_data_set())}
                            />
                        </div>
                        <div className="page-settings">
                            <div className="info">
                                <Calendar
                                    page={'kyc'}
                                    startDate={this.props.startDate}
                                    endDate={this.props.endDate}
                                />
                            </div>
                        </div>
                    </div>
                    {
                        JSON.parse(localStorage.getItem('currUserType')) === "bkash_user" ?

                            <div className="row">
                                <div className="col s12 m12 l6 upload-section">
                                    {this.props.actions && this.props.actions.upload ?
                                        <div className="uploading">
                                            {this.props.actions.upload}
                                            <div className="progress">
                                                <div className="indeterminate"></div>
                                            </div>
                                        </div>
                                        : <Dropzone onDrop={acceptedFiles => this.uploadFile(acceptedFiles[0])}>
                                            {({ getRootProps, getInputProps }) => (
                                                <section className="drag-zone-section">
                                                    <div className="drag-zone" {...getRootProps()}>
                                                        <input className="upload" {...getInputProps()} />
                                                        <div className="decor">
                                                            <img src={upload_img} alt="upload" />
                                                            <div className="info">
                                                                <div className="big-info">Upload Activated List</div>

                                                                <div className="small-info">
                                                                    Drop a file here, or <span className="underline">Select From Computer...</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>}
                                </div>
                                <div className="col s12 m12 l6 recjection-section">
                                    {this.props.rejectionActions && this.props.rejectionActions.upload ?
                                        <div className="uploading">
                                            {this.props.rejectionActions.upload}
                                            <div className="progress">
                                                <div className="indeterminate"></div>
                                            </div>
                                        </div>
                                        : <Dropzone onDrop={acceptedFiles => this.uploadRejectionFile(acceptedFiles[0])}>
                                            {({ getRootProps, getInputProps }) => (
                                                <section className="drag-zone-section">
                                                    <div className="drag-zone" {...getRootProps()}>
                                                        <input className="upload" {...getInputProps()} />
                                                        <div className="decor">
                                                            <img src={rejection_img} alt="upload" />
                                                            <div className="info">
                                                                <div className="big-info">Upload Rejected List</div>

                                                                <div className="small-info">
                                                                    Drop a file here, or <span className="underline">Select From Computer...</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>}
                                </div>
                            </div>
                            : null
                    }
                    <Pagination
                        page={'processed'}
                        currentPage={this.props.currentPage}
                        total={this.state.total}
                        limit={this.state.limit}
                    />
                    <Table
                        column={JSON.parse(localStorage.getItem('currUserType')) === "agency_user" ? table_col_agency : table_col_bkash}
                        data={this.state.processed}

                        action="status"

                        table_type={this.props.heading}
                        classNamePrefix='table'
                    />
                    <Pagination
                        page={'processed'}
                        currentPage={this.props.currentPage}
                        total={this.state.total}
                        limit={this.state.limit}
                    />
                </div>
            </>

        )
    }
}

const mapStateToProps = (state) => {
    let startDate = state.calendar.kyc ? state.calendar.kyc.startDate : state.calendar.default.startDate
    let endDate = state.calendar.kyc ? state.calendar.kyc.endDate : state.calendar.default.endDate
    let currentPage = state.page.processed ? state.page.processed.currentPage : state.page.default.currentPage
    let filterElement = state.filterElement.kyc ? state.filterElement.kyc : state.filterElement.default
    let filterHide = state.filter.filterHide
    let actions = state.uplaodAction.processed ? state.uplaodAction.processed : state.uplaodAction.default
    let rejectionActions = state.uploadRejected.processed ? state.uploadRejected.processed : state.uploadRejected.default

    return (
        { startDate, endDate, currentPage, filterElement, filterHide, actions, rejectionActions }
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateFilterElement: (filter) => dispatch(updateFilterElement(filter)),
        updateFilterHide: (filterHide) => dispatch(updateFilterHide(filterHide)),
        updateUploadAction: (action) => dispatch(updateUploadAction(action)),
        updateRejectedAction: (action) => dispatch(updateRejectedAction(action))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Processed)