export const setPage = (page, currentPage) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_PAGE', page, currentPage })
    }
}

export const nextPage = (page, currentPage) => {
    return (dispatch, getState) => {
        const nextPage = currentPage + 1
        dispatch({ type: 'NEXT_PAGE', page, nextPage })
    }
}

export const prePage = (page, currentPage) => {
    return (dispatch, getState) => {
        const prePage = currentPage - 1;
        dispatch({ type: 'PRE_PAGE', page, prePage })
    }
}