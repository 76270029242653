import React, { Component } from 'react'
import { Map, Marker, TileLayer } from 'react-leaflet'

import '../styles/outletCallLocation.css'

export default class OutletLocation extends Component {
    render() {
        return (< div className="outlet-call-location-container" >
            <Map center={this.props.location} zoom={7} >
                <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker
                    position={this.props.location}
                >
                </Marker>
            </Map >
        </div>
        )
    }
}