import React, { Component } from "react"
// import FieldForceCard from "./components/FieldForceCard/FieldForceCard"
import "./index.scss"
import UserManagementSection from "./components/UserManagementSection/UserManagementSection"
// import LeaveManagementSection from "./components/LeaveManagementSection/LeaveManagementSection"
import Footer from "../Footer/Footer"

class Admin extends Component {
  render() {
    return (
      <div className="main-container">
        {/* <LeaveManagementSection /> */}
        <UserManagementSection />
        <Footer />
      </div>
    )
  }
}

export default Admin
